import { useEffect, useState } from 'react';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference } from 'calls/hooks/index.js';
import { ButtonType } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import { languages } from 'constants/languageLine.js';
import { dialOutTranslator } from 'api/sipProvider.js';
import CustomSelect from 'components/Select.jsx';
import { participant as CallsParticipant } from '@solaborate/calls';

/**
 * @param {object} props
 * @param {(err) => void} props.setError
 * @param {(err) => void} props.onDismiss
 * @param {object} props.interpretationConfig
 */

const InviteLanguageLineView = ({ onDismiss, setError, interpretationConfig }) => {
	const intl = useIntl();
	const callEnums = { VideoAndAudio: 1, AudioOnly: 2 };
	const callTypes = [
		{ id: callEnums.VideoAndAudio, name: intl.formatMessage({ id: 'videoAndAudioOption' }) },
		{ id: callEnums.AudioOnly, name: intl.formatMessage({ id: 'audioOnlyOption' }) },
	];
	const conference = useConference();
	const [selectedLanguageLine, setSelectedLanguageLine] = useState({
		label: '',
		value: '',
		index: 999,
	});
	const [patientPreferredLanguage, setPatientPreferredLanguage] = useState(null);
	const [selectedCallTypeId, setSelectedCallTypeId] = useState(
		interpretationConfig?.callType ? callTypes.find(item => interpretationConfig.callType === item.id).id : callTypes[0].id
	);

	useEffect(() => {
		const patientInfo = conference.additionalData.find(item => item.key === 'patientInfo');
		if (patientInfo) {
			const { preferredLanguage } = patientInfo.value;
			const foundLanguage = languages.find(
				item => preferredLanguage && item.label.toLowerCase().startsWith(preferredLanguage.toLowerCase())
			);
			if (!foundLanguage) {
				return;
			}
			setSelectedLanguageLine(foundLanguage);
			setPatientPreferredLanguage({ ...foundLanguage });
		}
	}, []);

	const getLanguageLineNumber = () => {
		const defaultNumber = 'test-audio-siptestlls@sip.languageline.com';
		return interpretationConfig.authCode === defaultNumber
			? defaultNumber
			: selectedLanguageLine.value.replace('YOUR-AUTHCODE-HERE', interpretationConfig.authCode);
	};

	const removeParticipantOnNoAnswer = (participantId, message = '') => {
		setTimeout(() => {
			const participant = conference.participants.get(participantId);
			const isParticipantConnecting = participant && participant.state instanceof CallsParticipant.StateConnecting;
			if (isParticipantConnecting) {
				conference.removeParticipant(participantId);
				onDismiss(message);
			}
		}, 60000);
	};

	const onLanguageLineSelect = event => {
		const foundItem = languages.find(language => language.value === event.target.value);
		if (!foundItem) {
			return;
		}
		setSelectedLanguageLine(foundItem);
	};

	const inviteDialOutTranslator = async () => {
		if (interpretationConfig.authCode && !selectedLanguageLine.value) {
			return;
		}
		const numberToDial = getLanguageLineNumber();
		const response = await dialOutTranslator(numberToDial, conference.conferenceId, selectedCallTypeId === callEnums.AudioOnly);
		if (response.error) {
			setError(translate('serviceProvicerNoAnswer'));
		} else {
			removeParticipantOnNoAnswer(response.participantId, intl.formatMessage({ id: 'unableToConnectInterpreter' }));
			onDismiss();
		}
	};

	return (
		<>
			<Modal.Content>
				<div className='translation-services'>
					{patientPreferredLanguage && (
						<div>
							{translate('patientPreferredLanguage')}: {patientPreferredLanguage.label}
						</div>
					)}
					<div className='language-label'>
						<p>{translate('language')}</p>
					</div>
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label='Target Language'
							name='selectLanguage'
							items={languages}
							valueField='value'
							textField='label'
							placeholder='Select Language'
							value={selectedLanguageLine.value}
							onSelect={onLanguageLineSelect}
						/>
					</div>
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label={intl.formatMessage({ id: 'callType' })}
							name='selectCallType'
							items={callTypes}
							valueField='id'
							textField='name'
							placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'callType' })}`}
							value={selectedCallTypeId}
							onSelect={event => setSelectedCallTypeId(+event.target.value)}
						/>
					</div>
					<div className='interpreter-desc'>
						<p>{translate('languageLineDesc')}</p>
					</div>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button
					type='submit'
					onClick={inviteDialOutTranslator}
					variant={ButtonType.SUBMIT}
					disabled={!selectedLanguageLine.value}>
					{translate('inviteInterpreter')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default InviteLanguageLineView;
