import { useState, useEffect, useRef, useContext, Fragment } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isChrome, isEdgeChromium, isMobile } from 'react-device-detect';
import { enums, conference as Conference } from '@solaborate/calls';
import { Cam, Mic, ScreenAudio, ScreenShare, ErrorCanceled } from '@solaborate/calls/webrtc';
import {
	CallControls,
	IconButton,
	Duration,
	Dropdown,
	List,
	Switch,
	Tooltip,
	RangeSlider,
	PatientInformation,
	CallEventInformation,
	RectangleButton,
} from 'calls/components/index.js';
import {
	ScreenshareIcon,
	ScreenshareOffIcon,
	CallEndIcon,
	VideocamIcon,
	VideocamOffIcon,
	MicIcon,
	MicOffIcon,
	TVIcon,
	NightVisionIconOff,
	NightVisionIconOn,
	CollapseIcon,
	ExpandIcon,
	VolumeUpIcon,
	InviteUserIcon,
	ControlCameraIcon,
	ControlCameraOffIcon,
	NonGridViewIcon,
	GridViewIcon,
	MedicalInfoIcon,
	PrescriptionsIcon,
	ProceduresIcon,
	NotesIcon,
	PowerOffIcon,
	VolumeOffIcon,
	ContentCopyIcon,
	PhysicalTherapyIcon,
	StreamSettingsIcon,
	HdmiIcon,
	PlusIcon,
	MinusIcon,
	VolumeMutedIcon,
	SelectBackgroundIcon,
	LiveCaptionsIcon,
	LiveCaptionsDisabledIcon,
	AppsIcon,
	MaximizeIcon,
	MinimizeIcon,
} from 'calls/icons/index.js';
import {
	useConference,
	useLocalParticipant,
	useConferenceConfigurations,
	useHelloDeviceState,
	useControllerTracks,
	useScreenType,
	useConferenceState,
	useConferenceParticipants,
} from 'calls/hooks/index.js';
import {
	isMedicalInfoModalsOpen,
	getCallsControlsButtonBackground,
	changeLocalParticipantBackground,
	findRemoteHelloParticipant,
} from 'calls/helpers/index.js';
import { DropdownDirection, ControlsActions, TvProtocols, WindowSize } from 'calls/enums/index.js';
import { HealthTools, HealthToolButtons } from 'calls/enums/HealthTools.js';
import { MedicalControlsActions } from 'calls/constants/index.js';
import LocalParticipant from 'calls/LocalParticipant.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import {
	StreamError,
	MediaTypes,
	MediaPermissions,
	UserPermissionDeniedErrors,
	ErrorComponentTypes,
	UserRoles,
	CallTypes,
	UserTypes,
	DeviceCommands,
	AudioOutputDevice,
} from 'constants/enums.js';
import { RoundingSettings, UserSettings, MedicalDevicesConfigsList } from 'constants/configurationEnums.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserRole } from 'infrastructure/auth.js';
import {
	checkForPermission,
	getCallsButtonColor,
	getRoleConfigurationValue,
	getSelectedBackgroundImage,
	getSomeRoleConfigurationsValues,
	getUserBackgroundParams,
	isMobileOrTablet,
} from 'infrastructure/helpers/commonHelpers.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { isAnyAiSettingEnabled, useAiRoomSettings } from 'infrastructure/helpers/aiRoomSettingsHelper.js';
import { v4 as uuidv4 } from 'uuid';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';

const toggleConfigurations = ({ configs, actions, targetAction, value }) => {
	actions.forEach(action => {
		configs.onConfigurationToggleAction(action, action === targetAction ? value : false);
	});
};

/**
 * @type {import('styled-components').StyledComponent<div, any, { $isDarkMode: boolean }, never>}
 */
const StyledInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	> div {
		display: flex;
		align-items: center;
		margin-right: 3px;

		button {
			padding: 0;
			box-shadow: none;
			margin-left: ${LightTheme.spacing[1]}px;

			span {
				font-size: 14px;
			}
		}

		+ p {
			color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
			font-size: 14px;
			margin: 0;
			padding: 0;
		}
	}

	@media (max-width: ${WindowSize.TABLET}px) {
		align-items: center;
		border-right: none;

		p {
			color: ${LightTheme.colors.grayTwo};
			margin: 0 !important;
			padding: 0 !important;
		}
	}
`;

/**
 * @type {import('styled-components').StyledComponent<List, any, { $isCECTV: boolean, $isRightToLeft: boolean, $isDarkMode: boolean }, never>}
 */
const StyledTVControls = styled(List)`
	padding: 1px 3px;
	width: ${LightTheme.sizes[5] * 2 + LightTheme.sizes[1]}px;

	li {
		border-top: 1px solid ${LightTheme.colors.grayNine};

		button {
			padding-right: ${LightTheme.spacing[4]}px;
			border-radius: ${LightTheme.spacing[2] + 2}px;
			margin: 2px 0px;

			svg:last-child,
			span:last-child {
				margin: ${props => (props.$isRightToLeft ? '0 auto 0 0' : '0 0 0 auto')};
			}

			> div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				span {
					color: ${LightTheme.colors.graySix};
				}

				span:last-child {
					font-size: ${LightTheme.typography.p - 4}px;
					line-height: 14px;
				}
			}

			&.active {
				svg {
					path {
						stroke: ${LightTheme.colors.blueFour};
					}
				}
			}
		}

		&:first-child {
			border: 0px;
			button {
				span {
					span {
						&::before {
							background: ${LightTheme.colors.grayTwo};
						}
						&::after {
							background: ${LightTheme.colors.greenTwo};
						}
					}
				}
			}
		}

		${props =>
			!props.$isCECTV &&
			css`
				&:nth-child(2) &:not(.volume) {
					button {
						padding: 3px ${LightTheme.spacing[3]}px;
					}
				}
			`}
	}

	.volume-wrapper {
		width: 100%;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		span {
			margin-right: 8px;
		}
	}

	.volume-icon {
		margin-right: ${LightTheme.spacing[2]}px;
		button {
			padding: 0px;
			margin: 0px;
			margin-right: ${props => (props.$isRightToLeft ? '0' : `${LightTheme.spacing[2]}px`)};
			border-radius: 0px;
			background: transparent;
		}
	}

	.volume {
		display: flex;
		align-items: center;
		column-gap: 5px;
		button {
			padding: 2px;
			padding-bottom: 0px;
			border-radius: 3px;
			background: ${LightTheme.colors.grayTwo};
			svg {
				margin: 0;
			}
		}

		> div {
			padding-bottom: ${LightTheme.spacing[1]}px;
		}
	}

	p {
		font-size: var(--spacing-xl);
		color: ${LightTheme.colors.graySix};
		padding: 0;
		margin: 0;
		margin-left: ${LightTheme.spacing[2]}px;
	}

	${props =>
		props.$isCECTV &&
		css`
			button {
				> button {
					padding: 2px;
					margin: 0;

					span {
						margin: 0;
					}

					+ span {
						flex: 1;
						text-align: center;
						margin: 0;
					}
				}
			}
		`}

	${props =>
		props.$isDarkMode &&
		css`
			li {
				button {
					> div {
						span {
							color: ${DarkTheme.colors.grayFive};
						}
					}
				}

				&:first-child {
					button {
						span {
							span {
								&::before {
									background: ${DarkTheme.colors.grayTwo};
								}
								&::after {
									background: ${DarkTheme.colors.greenTwo};
								}
							}
						}
					}
				}
			}

			.volume {
				button {
					background: ${DarkTheme.colors.grayTwo};
				}
			}

			p {
				color: ${DarkTheme.colors.grayFive};
			}
		`}
`;

const LocalParticipantMic = () => {
	const localParticipant = useLocalParticipant();
	const conferenceConfigurations = useConferenceConfigurations();
	const localParticipantTracks = useControllerTracks(localParticipant.localTrackController);
	const intl = useIntl();
	const dispatch = useDispatch();

	const handleLocalParticipantMicErrors = error => {
		if (error?.error?.name === UserPermissionDeniedErrors.NotFoundError) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: StreamError.MICROPHONE_NOT_FOUND.type,
				})
			);
		} else if (error?.error?.name === UserPermissionDeniedErrors.NotAllowedError) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		} else {
			conferenceConfigurations.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
		}
	};

	return (
		<Tooltip
			text={intl.formatMessage({ id: localParticipantTracks[Mic] ? 'turnOffMic' : 'turnOnMic' })}
			mobileTitle={intl.formatMessage({ id: localParticipantTracks[Mic] ? 'mute' : 'unmute' })}>
			<IconButton
				background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
				color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
				borderColor={LightTheme.colors.grayTen02}
				onClick={async evt => {
					evt.preventDefault();
					evt.stopPropagation();
					try {
						conferenceConfigurations.setMicMutedByOwnerMessage('');
						await localParticipant.localTrackController.toggle(Mic);
					} catch (error) {
						handleLocalParticipantMicErrors(error);
					}
				}}>
				{localParticipantTracks[Mic] && <MicIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />}
				{!localParticipantTracks[Mic] && <MicOffIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />}
			</IconButton>
		</Tooltip>
	);
};

const MedicalInfoButton = ({ closeLiveExaminationView }) => {
	const conferenceConfigurations = useConferenceConfigurations();
	const [isMedicalInfoOpen, setIsMedicalInfoOpen] = useState(false);
	const { roundingConfigurations } = conferenceConfigurations;

	const handleMedicalOptionClick = action => {
		toggleConfigurations({ configs: conferenceConfigurations, actions: MedicalControlsActions, targetAction: '', value: null });
		conferenceConfigurations.onConfigurationToggleAction(action);
		setIsMedicalInfoOpen(false);
		closeLiveExaminationView();
	};

	const medicalInfoClick = evt => {
		evt.stopPropagation();
		setIsMedicalInfoOpen(prevState => !prevState);
	};

	const showPhysicalTherapyButton = () =>
		conferenceConfigurations.featureFlags.usePhysicalExercises &&
		conferenceConfigurations.visitId &&
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientPhysicalTherapy);

	return (
		getUserRole() === UserRoles.DOCTOR && (
			<Dropdown
				offset={LightTheme.spacing[3]}
				isOpen={isMedicalInfoOpen}
				direction={DropdownDirection.TOP_RIGHT}
				onClickOutside={() => setIsMedicalInfoOpen(false)}>
				<Dropdown.Button>
					<RectangleButton
						darkMode={conferenceConfigurations.isDarkMode}
						isActive={isMedicalInfoOpen || isMedicalInfoModalsOpen(conferenceConfigurations.medicalDataControls)}
						onClick={evt => {
							evt.stopPropagation();
							MedicalControlsActions.forEach(controlAction =>
								conferenceConfigurations.onConfigurationToggleAction(controlAction, false)
							);
							medicalInfoClick(evt);
						}}
						icon={MedicalInfoIcon}
						label={translate('medicalInfo')}
					/>
				</Dropdown.Button>
				<Dropdown.Items>
					<List>
						{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientDiagnoses) && (
							<List.Item onClick={() => handleMedicalOptionClick(ControlsActions.TOGGLE_DIAGNOSES)}>
								<MedicalInfoIcon />
								{translate('add')} {translate('diagnosis')}
							</List.Item>
						)}
						{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientProcedures) && (
							<List.Item onClick={() => handleMedicalOptionClick(ControlsActions.TOGGLE_PROCEDURES)}>
								<ProceduresIcon />
								{translate('add')} {translate('procedure')}
							</List.Item>
						)}
						{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientNotes) && (
							<List.Item onClick={() => handleMedicalOptionClick(ControlsActions.TOGGLE_NOTES)}>
								<NotesIcon /> {translate('addNote')}
							</List.Item>
						)}
						{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.PatientPrescriptions) && (
							<List.Item onClick={() => handleMedicalOptionClick(ControlsActions.TOGGLE_PRESCRIPTIONS)}>
								<PrescriptionsIcon />
								{translate('addPrescription')}
							</List.Item>
						)}
						{showPhysicalTherapyButton() && (
							<List.Item onClick={() => handleMedicalOptionClick(ControlsActions.TOGGLE_PHYSICAL_EXERCISES)}>
								<PhysicalTherapyIcon />
								{translate('physicalTherapy')}
							</List.Item>
						)}
					</List>
				</Dropdown.Items>
			</Dropdown>
		)
	);
};

const PatientMeasurementsButtons = ({ closeLiveExaminationView }) => {
	const intl = useIntl();
	const conferenceConfigurations = useConferenceConfigurations();
	const conferenceParticipants = useConferenceParticipants();
	const helloParticipant = findRemoteHelloParticipant(conferenceParticipants);
	const aiRoomSettings = useAiRoomSettings(helloParticipant?.objectId);
	const { roundingConfigurations } = conferenceConfigurations;
	const renderedButtonsCountRef = useRef(0);
	const [isDropdownFeaturesOpen, setIsDropdownFeaturesOpen] = useState(false);
	const [isFeaturesButtonGrouped, setIsFeaturesButtonGrouped] = useState(false);

	const isLiveExaminationConfigsOn = () =>
		(getRoleConfigurationValue(roundingConfigurations, RoundingSettings.MedicalDevices) &&
			getSomeRoleConfigurationsValues(roundingConfigurations, MedicalDevicesConfigsList)) ||
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.ExamKit);

	const isTelemetryConfigsOn = () =>
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.LifeSignalsPatch) ||
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.BiobeatPatch);

	const shouldShowTelemetryButton = () =>
		isTelemetryConfigsOn() &&
		[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(getUserRole()) &&
		!isMobile;

	const shouldShowPatientHistoryButton = () =>
		getSomeRoleConfigurationsValues(roundingConfigurations, [
			RoundingSettings.RoundingHealthData,
			RoundingSettings.Wearables,
			RoundingSettings.RoundingVisits,
			RoundingSettings.RoundingAlertHistory,
		]);

	const shouldShowCareEventsButton = () =>
		getUserRole() === UserRoles.NURSE &&
		getSomeRoleConfigurationsValues(roundingConfigurations, [
			RoundingSettings.CareEventsForNurses,
			RoundingSettings.CareEventsForSafetyCompanions,
		]);

	const getCareEventsTypeName = () => {
		if (getSomeRoleConfigurationsValues(roundingConfigurations, [RoundingSettings.CareEventsForSafetyCompanions])) {
			return 'safetyCompanions';
		} else {
			return 'careEvents';
		}
	};

	const shouldShowWhiteboard = () =>
		getSomeRoleConfigurationsValues(roundingConfigurations, [RoundingSettings.Whiteboard, RoundingSettings.DisplayControl]) &&
		conferenceConfigurations.patientInfo &&
		!conferenceConfigurations.patientInfo.isVirtualPatient;

	const shouldShowRoomSign = () =>
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.RoomSign) &&
		conferenceConfigurations.patientInfo &&
		!conferenceConfigurations.patientInfo.isVirtualPatient;

	const shouldShowAi = () => getUserRole() === UserRoles.NURSE && isAnyAiSettingEnabled(aiRoomSettings);

	const handleButtonClick = params => {
		toggleConfigurations(params);
		if (params.targetAction !== ControlsActions.TOGGLE_LIVE_EXAMINATIONS) {
			closeLiveExaminationView();
		}
	};

	const healthButtons = {
		[HealthTools.CARE_EVENTS]: {
			...HealthToolButtons[HealthTools.CARE_EVENTS],
			label: getCareEventsTypeName(),
			shouldShowFeatureButton: shouldShowCareEventsButton,
		},
		[HealthTools.TELEMETRY]: {
			...HealthToolButtons[HealthTools.TELEMETRY],
			shouldShowFeatureButton: shouldShowTelemetryButton,
		},
		[HealthTools.LIVE_EXAMINATIONS]: {
			...HealthToolButtons[HealthTools.LIVE_EXAMINATIONS],
			shouldShowFeatureButton: isLiveExaminationConfigsOn,
		},
		[HealthTools.PATIENT_HISTORY]: {
			...HealthToolButtons[HealthTools.PATIENT_HISTORY],
			shouldShowFeatureButton: shouldShowPatientHistoryButton,
		},
		[HealthTools.WHITEBOARD]: {
			...HealthToolButtons[HealthTools.WHITEBOARD],
			shouldShowFeatureButton: shouldShowWhiteboard,
		},
		[HealthTools.ROOM_SIGN]: {
			...HealthToolButtons[HealthTools.ROOM_SIGN],
			shouldShowFeatureButton: shouldShowRoomSign,
		},
		[HealthTools.AI]: {
			...HealthToolButtons[HealthTools.AI],
			shouldShowFeatureButton: shouldShowAi,
		},
	};

	useEffect(() => {
		const handleResize = () => setIsFeaturesButtonGrouped(window.innerWidth <= 1200 && renderedButtonsCountRef.current > 2);

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, [renderedButtonsCountRef.current]);

	const renderFeatureButtons = (isGrouped = false) => {
		renderedButtonsCountRef.current = 0;
		return Object.entries(healthButtons).map(([key, config]) => {
			const { icon: Icon, label, isActive, onClick, shouldShowFeatureButton } = config;

			if (!shouldShowFeatureButton()) {
				return null;
			}

			renderedButtonsCountRef.current += 1;

			return (
				<Fragment key={key}>
					{isGrouped && (
						<List.Item
							key={key}
							onClick={() => {
								setIsDropdownFeaturesOpen(false);
								onClick(conferenceConfigurations, handleButtonClick);
							}}>
							<Icon />
							{translate(label)}
						</List.Item>
					)}
					{!isGrouped && (
						<RectangleButton
							key={key}
							darkMode={conferenceConfigurations.isDarkMode}
							isActive={isActive(conferenceConfigurations)}
							onClick={evt => {
								evt.stopPropagation();
								onClick(conferenceConfigurations, handleButtonClick);
							}}
							icon={Icon}
							label={translate(label)}
							{...(+key === HealthTools.TELEMETRY && { iconWidth: 22, iconHeight: 19 })}
						/>
					)}
				</Fragment>
			);
		});
	};

	return (
		<>
			{isFeaturesButtonGrouped && (
				<Tooltip text={!isDropdownFeaturesOpen && intl.formatMessage({ id: 'showFeatures' })}>
					<Dropdown
						offset={LightTheme.spacing[3]}
						isOpen={isDropdownFeaturesOpen}
						direction={DropdownDirection.TOP_RIGHT}
						onClickOutside={() => setIsDropdownFeaturesOpen(false)}>
						<Dropdown.Button>
							<RectangleButton
								darkMode={conferenceConfigurations.isDarkMode}
								isActive={isDropdownFeaturesOpen}
								onClick={evt => {
									evt.stopPropagation();
									setIsDropdownFeaturesOpen(prevState => !prevState);
								}}
								icon={AppsIcon}
								label={intl.formatMessage({ id: 'features' })}
							/>
						</Dropdown.Button>
						<Dropdown.Items>
							<List>{renderFeatureButtons(true)}</List>
						</Dropdown.Items>
					</Dropdown>
				</Tooltip>
			)}
			{!isFeaturesButtonGrouped && renderFeatureButtons()}
		</>
	);
};

/**
 * @param {object} props
 * @param {import('calls/RemoteHelloParticipant.js').default} props.helloParticipant
 */
const HelloDeviceControls = ({ helloParticipant }) => {
	const helloDeviceState = useHelloDeviceState(helloParticipant);
	const [isTvControlsOpen, setIsTvControlsOpen] = useState(false);
	const { tvBrand } = helloDeviceState;
	const [isTvPowerOn, setIsTvPowerOn] = useState(helloDeviceState.tvPowerOn);
	const [source, setSource] = useState(+helloDeviceState.hdmiPort);
	const conferenceConfigurations = useConferenceConfigurations();
	const [previousTvVolumeRange, setPreviousTvVolumeRange] = useState(helloDeviceState.tvVolume);
	const [audioOutputSelected, setAudioOutputSelected] = useState(
		helloDeviceState.audioOutput === AudioOutputDevice.HELLO ? translate('tvSpeakerSelected') : translate('helloSpeakerSelected')
	);
	const [audioSource, setAudioSource] = useState(helloDeviceState.audioOutput);
	const socket = useContext(SocketContext);
	const timeOut = useRef(null);
	const conference = useConference();
	const isTvMuted =
		conferenceConfigurations.tvVolumeRange <= 0 && conferenceConfigurations.tvVolumeRange >= -100 && helloDeviceState?.tvPowerOn;

	const handleAudioOutputResponse = data => {
		if (conference.conferenceId !== data.conferenceId) {
			return;
		}
		const audioOutputSelectedResult =
			data.audioOutputDevice === AudioOutputDevice.HELLO ? translate('tvSpeakerSelected') : translate('helloSpeakerSelected');
		setAudioSource(data.audioOutputDevice);
		clearTimeout(timeOut.current);
		setAudioOutputSelected(audioOutputSelectedResult);
	};

	useEffect(() => {
		timeOut.current = setTimeout(() => {
			setAudioOutputSelected(null);
		}, 3000);
		return () => clearTimeout(timeOut.current);
	}, [audioOutputSelected]);

	useEffect(() => {
		socket.on(SocketEvents.HelloDevice.ON_AUDIO_OUTPUT_DEVICE, handleAudioOutputResponse);
		return () => {
			socket.off(SocketEvents.HelloDevice.ON_AUDIO_OUTPUT_DEVICE, handleAudioOutputResponse);
		};
	}, [socket]);

	useEffect(() => {
		if (tvBrand === TvProtocols.EX_LINK) {
			conferenceConfigurations.setTvVolumeRange(1);
		}
	}, [tvBrand]);

	useEffect(() => {
		setSource(helloDeviceState.hdmiPort);

		setIsTvPowerOn(helloDeviceState.tvPowerOn);

		if (helloDeviceState.tvPowerOn === false) {
			conferenceConfigurations.setTvVolumeRange(0);
		}

		if (helloDeviceState.tvPowerOn) {
			conferenceConfigurations.setTvVolumeRange(+helloDeviceState.tvVolume);
			setPreviousTvVolumeRange(Math.abs(helloDeviceState.tvVolume));
		}

		if (helloDeviceState.hdmiPort === 1) {
			setSource(enums.SerialTVCommands.SWITCH_HDMI1);
		}
		if (helloDeviceState.hdmiPort === 2) {
			setSource(enums.SerialTVCommands.SWITCH_HDMI2);
		}
	}, [helloDeviceState]);

	const sourceStatus = active => {
		return <span>{translate(active ? 'tvActiveOnThiSource' : 'switchToHelloSource')}</span>;
	};

	const changeAudioOutput = () => {
		helloParticipant.sendDeviceCommand(
			DeviceCommands.SWITCH_AUDIO_OUTPUT,
			JSON.stringify({
				audioOutputDevice: audioSource === AudioOutputDevice.HELLO ? AudioOutputDevice.TV : AudioOutputDevice.HELLO,
			})
		);
	};

	return (
		<>
			<Dropdown
				offset={LightTheme.spacing[3]}
				isOpen={isTvControlsOpen}
				direction={DropdownDirection.TOP_RIGHT}
				onClickOutside={() => setIsTvControlsOpen(false)}>
				<Dropdown.Button>
					<RectangleButton
						disabled={TvProtocols.UNKNOWN === tvBrand || TvProtocols.NO_TV === tvBrand}
						darkMode={conferenceConfigurations.isDarkMode}
						isActive={isTvControlsOpen}
						onClick={evt => {
							evt.stopPropagation();
							setIsTvControlsOpen(prevState => !prevState);
						}}
						icon={TVIcon}
						label={translate('tvControls')}
					/>
				</Dropdown.Button>
				<Dropdown.Items>
					<StyledTVControls
						$isCECTV={TvProtocols.CEC === tvBrand}
						$isRightToLeft={conferenceConfigurations.isRightToLeft}
						$isDarkMode={conferenceConfigurations.isDarkMode}>
						{TvProtocols.GET_WELL !== tvBrand && (
							<List.Item
								onClick={() => {
									setIsTvPowerOn(prevState => !prevState);
									helloParticipant.sendTVSerialCommand(
										isTvPowerOn ? enums.SerialTVCommands.POWER_OFF : enums.SerialTVCommands.POWER_ON
									);
								}}>
								<PowerOffIcon />
								<div>
									<span>TV</span>
									{isTvPowerOn && <span>{translate('togglePatientsTvON')}</span>}
									{!isTvPowerOn && <span>{translate('togglePatientsTvOFF')}</span>}
								</div>
								<Switch checked={isTvPowerOn} readOnly />
							</List.Item>
						)}
						{(isTvPowerOn || TvProtocols.GET_WELL === tvBrand) && (
							<>
								{TvProtocols.CEC !== tvBrand && (
									<>
										<List.Item onClick={() => null}>
											<div className='volume-icon'>
												<Button
													onClick={() => {
														if (!(TvProtocols.GET_WELL === tvBrand)) {
															if (conferenceConfigurations.tvVolumeRange > 0) {
																setPreviousTvVolumeRange(conferenceConfigurations.tvVolumeRange);
																helloParticipant.sendTVSerialCommand(
																	enums.SerialTVCommands.VOLUME_CHANGE,
																	conferenceConfigurations.tvVolumeRange - 101
																);
																conferenceConfigurations.setTvVolumeRange(0);
															} else {
																conferenceConfigurations.setTvVolumeRange(previousTvVolumeRange);
																helloParticipant.sendTVSerialCommand(enums.SerialTVCommands.VOLUME_CHANGE, previousTvVolumeRange);
															}
														}
													}}
													text={isTvMuted ? <VolumeMutedIcon /> : <VolumeUpIcon />}
												/>
											</div>
											<div className={TvProtocols.GET_WELL === tvBrand ? 'volume-wrapper' : ''}>
												<span>{translate('volume')}</span>
												<div className='volume'>
													{TvProtocols.GET_WELL !== tvBrand && (
														<Button
															onClick={() => {
																if (conferenceConfigurations.tvVolumeRange > 0) {
																	conferenceConfigurations.setTvVolumeRange(conferenceConfigurations.tvVolumeRange - 1);
																	helloParticipant.sendTVSerialCommand(
																		enums.SerialTVCommands.VOLUME_CHANGE,
																		conferenceConfigurations.tvVolumeRange - 1
																	);
																}
															}}
															disabled={isTvMuted}
															svgIcon={
																<MinusIcon
																	strokeColor={
																		conferenceConfigurations.isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive
																	}
																/>
															}
														/>
													)}
													<div>
														<RangeSlider
															style={{
																backgroundSize: `${
																	(conferenceConfigurations.tvVolumeRange <= 0
																		? 0
																		: conferenceConfigurations.tvVolumeRange * 100) / 100
																}% 100%`,
															}}
															min={0}
															max={100}
															value={conferenceConfigurations.tvVolumeRange}
															onChange={evt => {
																conferenceConfigurations.setTvVolumeRange(+evt.target.value);
															}}
															onMouseUp={evt => {
																helloParticipant.sendTVSerialCommand(enums.SerialTVCommands.VOLUME_CHANGE, evt.target.value);
															}}
														/>
													</div>
													{TvProtocols.GET_WELL !== tvBrand && (
														<Button
															onClick={() => {
																if (conferenceConfigurations.tvVolumeRange < 100) {
																	const increaseTvVolume =
																		conferenceConfigurations.tvVolumeRange < 0
																			? Math.abs(conferenceConfigurations.tvVolumeRange) + 1
																			: conferenceConfigurations.tvVolumeRange + 1;
																	conferenceConfigurations.setTvVolumeRange(increaseTvVolume);
																	helloParticipant.sendTVSerialCommand(
																		enums.SerialTVCommands.VOLUME_CHANGE,
																		conferenceConfigurations.tvVolumeRange + 1
																	);
																}
															}}
															disabled={conferenceConfigurations.tvVolumeRange >= 100}
															svgIcon={
																<PlusIcon
																	strokeColor={
																		conferenceConfigurations.isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive
																	}
																/>
															}
														/>
													)}
												</div>
											</div>
											{conferenceConfigurations.tvVolumeRange > 0 && <p>{conferenceConfigurations.tvVolumeRange}</p>}
										</List.Item>
										{TvProtocols.GET_WELL !== tvBrand && (
											<>
												<List.Item
													isActive={source === enums.SerialTVCommands.SWITCH_HDMI1}
													onClick={() => {
														helloParticipant.sendTVSerialCommand(enums.SerialTVCommands.SWITCH_HDMI1);
														setSource(enums.SerialTVCommands.SWITCH_HDMI1);
													}}>
													<HdmiIcon
														color={
															source === enums.SerialTVCommands.SWITCH_HDMI1
																? LightTheme.colors.blueFour
																: LightTheme.colors.graySeven
														}
													/>
													<div>
														<span>HDMI 1</span>
														{sourceStatus(source === enums.SerialTVCommands.SWITCH_HDMI1)}
													</div>
												</List.Item>
												<List.Item
													isActive={source === enums.SerialTVCommands.SWITCH_HDMI2}
													onClick={() => {
														helloParticipant.sendTVSerialCommand(enums.SerialTVCommands.SWITCH_HDMI2);
														setSource(enums.SerialTVCommands.SWITCH_HDMI2);
													}}>
													<HdmiIcon
														color={
															source === enums.SerialTVCommands.SWITCH_HDMI2
																? LightTheme.colors.blueFour
																: LightTheme.colors.graySeven
														}
													/>
													<div>
														<span>HDMI 2</span>
														{sourceStatus(source === enums.SerialTVCommands.SWITCH_HDMI2)}
													</div>
												</List.Item>
												{tvBrand === TvProtocols.EX_LINK && (
													<List.Item
														isActive={source === enums.SerialTVCommands.SWITCH_HDMI3}
														onClick={() => {
															helloParticipant.sendTVSerialCommand(enums.SerialTVCommands.SWITCH_HDMI3);
															setSource(enums.SerialTVCommands.SWITCH_HDMI3);
														}}>
														<HdmiIcon
															color={
																source === enums.SerialTVCommands.SWITCH_HDMI3
																	? LightTheme.colors.blueFour
																	: LightTheme.colors.graySeven
															}
														/>
														<div>
															<span>HDMI 3</span>
															{sourceStatus(source === enums.SerialTVCommands.SWITCH_HDMI3)}
														</div>
													</List.Item>
												)}
												{[TvProtocols.EX_LINK, TvProtocols.MPI, TvProtocols.GET_WELL, TvProtocols.E_VIDEON].includes(tvBrand) && (
													<List.Item isActive={false} onClick={changeAudioOutput}>
														<HdmiIcon color={LightTheme.colors.graySeven} />
														<div>
															<span>{translate(audioSource === AudioOutputDevice.HELLO ? 'switchToTv' : 'switchToHello')}</span>
														</div>
													</List.Item>
												)}
											</>
										)}
									</>
								)}
								{TvProtocols.CEC === tvBrand && (
									<>
										<List.Item onClick={() => null}>
											<IconButton
												icon='remove'
												background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
												color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
												onClick={() => {
													helloParticipant.sendTVSerialCommand(enums.SerialTVCommands.VOLUME_CHANGE, 0);
												}}
											/>
											<span>{translate('volume')}</span>
											<IconButton
												icon='add'
												background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
												color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
												onClick={() => {
													helloParticipant.sendTVSerialCommand(enums.SerialTVCommands.VOLUME_CHANGE, 1);
												}}
											/>
										</List.Item>
										<List.Item
											isActive={source === enums.SerialTVCommands.SWITCH_HDMI1}
											onClick={() => {
												helloParticipant.sendTVSerialCommand(enums.SerialTVCommands.SWITCH_HDMI1);
												setSource(enums.SerialTVCommands.SWITCH_HDMI1);
											}}>
											<HdmiIcon
												color={
													source === enums.SerialTVCommands.SWITCH_HDMI1
														? LightTheme.colors.blueFour
														: LightTheme.colors.graySeven
												}
											/>
											<div>
												<span>HDMI</span>
												{sourceStatus(source === enums.SerialTVCommands.SWITCH_HDMI1)}
											</div>
										</List.Item>
									</>
								)}
							</>
						)}
					</StyledTVControls>
				</Dropdown.Items>
			</Dropdown>
		</>
	);
};

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant
 */
const MoreSettingsButton = ({ mainParticipant }) => {
	const intl = useIntl();
	const screenType = useScreenType();
	const conference = useConference();
	const conferenceConfigurations = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const deviceState = useHelloDeviceState(mainParticipant);
	const tracks = useControllerTracks(mainParticipant.remoteTrackController);
	const haveHelloParticipant = findRemoteHelloParticipant(useConferenceParticipants());
	const [isMoreSettingsOpen, setIsMoreSettingsOpen] = useState(false);
	const [isPatientView] = useState(conference.callType === enums.CallTypes.SECURITYCAM);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const { roundingConfigurations } = conferenceConfigurations;
	const liveExaminationButton = HealthToolButtons[HealthTools.LIVE_EXAMINATIONS];
	const selectedCamera = deviceState?.mediaDevices?.find(mediaDevice => mediaDevice.isActive);
	const filteredNightvisionDevices = deviceState?.mediaDevices?.filter(device => device.capabilities?.nightvision?.isSupported);

	const shouldShowEnableNightVisionButton = () =>
		!localParticipant.isGuest && deviceState && tracks[Cam] && selectedCamera?.capabilities?.nightvision?.isSupported;

	const shouldShowLiveCaptionsButton = () =>
		getSomeRoleConfigurationsValues(roundingConfigurations, [RoundingSettings.LiveCaptions]);

	const isNightVisionEnabled = isNewExperience
		? selectedCamera?.capabilities?.nightvision?.isEnabled
		: deviceState?.isNightVisionMode;

	const nightVisionMessage = isNightVisionEnabled ? 'disableNightvision' : 'enableNightvision';

	const getNightVisionIcon = () => {
		const NightVisionIcon = isNightVisionEnabled ? NightVisionIconOff : NightVisionIconOn;
		return <NightVisionIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />;
	};

	const shouldShowMultipartyCallButton = () =>
		screenType.isSmall &&
		!isPatientView &&
		!localParticipant.isGuest &&
		UserRoles.VISITOR !== getUserRole() &&
		getSomeRoleConfigurationsValues(roundingConfigurations, [
			RoundingSettings.MeetingUrl,
			RoundingSettings.InviteViaSms,
			RoundingSettings.DialIn,
			RoundingSettings.DialOut,
			RoundingSettings.TranslationServices,
			RoundingSettings.InviteViaEmail,
		]);

	const shouldShowLiveExamination = () =>
		screenType.isSmall &&
		!isPatientView &&
		(getRoleConfigurationValue(roundingConfigurations, RoundingSettings.MedicalDevices) ||
			getRoleConfigurationValue(roundingConfigurations, RoundingSettings.ExamKit));

	return (
		<>
			{getUserRole() === UserRoles.VISITOR && ( // familymember
				<>
					{mainParticipant instanceof RemoteHelloParticipant && shouldShowEnableNightVisionButton() && (
						<Tooltip
							text={intl.formatMessage({ id: nightVisionMessage })}
							mobileTitle={intl.formatMessage({ id: nightVisionMessage })}>
							<IconButton
								background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
								color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
								borderColor={LightTheme.colors.grayTen02}
								onClick={() => {
									if (!selectedCamera?.id) {
										return;
									}
									mainParticipant.sendMediaControlsEvent(
										enums.MediaControlsCommands.NIGHT_VISION,
										enums.MediaTypes.CAMERA,
										JSON.stringify({
											cameraId: selectedCamera?.id,
											enable: !selectedCamera?.capabilities?.nightvision?.isEnabled,
										})
									);
								}}>
								{getNightVisionIcon()}
							</IconButton>
						</Tooltip>
					)}
					{!conferenceConfigurations.isGridView &&
						conference.callType !== CallTypes.SECURITY_CAM &&
						conferenceConfigurations.isCameraControlsOpen && (
							<Tooltip text={intl.formatMessage({ id: conferenceConfigurations.isCameraControlsOpen ? 'hidePTZ' : 'showPTZ' })}>
								<IconButton
									background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
									color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
									borderColor={LightTheme.colors.grayTen02}
									onClick={() => {
										conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, false);
										conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_PARTICIPANTS_VIEW, false);
										conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_CAMERA_CONTROLS);
									}}>
									{conferenceConfigurations.isCameraControlsOpen && (
										<ControlCameraIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
									)}
									{!conferenceConfigurations.isCameraControlsOpen && (
										<ControlCameraOffIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
									)}
								</IconButton>
							</Tooltip>
						)}
				</>
			)}
			{getUserRole() !== UserRoles.VISITOR && ( // familymember
				<Tooltip
					text={!isMoreSettingsOpen && intl.formatMessage({ id: 'moreOptions' })}
					mobileTitle={intl.formatMessage({ id: 'others' })}>
					<Dropdown
						offset={LightTheme.spacing[3]}
						isOpen={isMoreSettingsOpen}
						direction={DropdownDirection.TOP_RIGHT}
						onClickOutside={() => setIsMoreSettingsOpen(false)}>
						<Dropdown.Button>
							<IconButton
								icon='more_vert'
								background={
									isMoreSettingsOpen
										? LightTheme.colors.blueFive
										: getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)
								}
								color={isMoreSettingsOpen ? LightTheme.colors.blueFour : getCallsButtonColor(conferenceConfigurations.isDarkMode)}
								borderColor={isMoreSettingsOpen ? LightTheme.colors.blueOne05 : LightTheme.colors.grayTen02}
								onClick={evt => {
									evt.stopPropagation();
									setIsMoreSettingsOpen(prevState => !prevState);
								}}
							/>
						</Dropdown.Button>
						<Dropdown.Items>
							<List>
								<li className='other-options'>
									{translate('otherOptions')}
									<span className='modal__close cursor-pointer' onClick={() => setIsMoreSettingsOpen(false)}>
										<i className='material-icons-outlined'>close</i>
									</span>
								</li>
								{shouldShowLiveExamination() && (
									<List.Item
										onClick={() => {
											setIsMoreSettingsOpen(false);
											liveExaminationButton.onClick(conferenceConfigurations, toggleConfigurations);
										}}>
										<liveExaminationButton.icon />
										{translate(liveExaminationButton.label)}
									</List.Item>
								)}
								{shouldShowMultipartyCallButton() && (
									<List.Item
										onClick={() => {
											setIsMoreSettingsOpen(false);
											conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL, false);
											conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL, false);
											conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL);
										}}>
										<InviteUserIcon />
										{translate('invitePeople')}
									</List.Item>
								)}
								{mainParticipant instanceof RemoteHelloParticipant && shouldShowEnableNightVisionButton() && (
									<>
										<List.Item
											onClick={() => {
												if (filteredNightvisionDevices.length > 1) {
													filteredNightvisionDevices.forEach(device => {
														if (!device?.id) {
															return;
														}
														mainParticipant.sendMediaControlsEvent(
															'nightVision',
															enums.MediaTypes.CAMERA,
															JSON.stringify({
																cameraId: device?.id,
																enable: !device?.capabilities?.nightvision?.isEnabled,
															})
														);
													});
													return;
												}
												if (!selectedCamera?.id) {
													return;
												}
												mainParticipant.sendMediaControlsEvent(
													'nightVision',
													enums.MediaTypes.CAMERA,
													JSON.stringify({
														cameraId: selectedCamera?.id,
														enable: !selectedCamera?.capabilities?.nightvision?.isEnabled,
													})
												);
											}}>
											{getNightVisionIcon()}
											{intl.formatMessage({ id: nightVisionMessage })}
										</List.Item>
									</>
								)}
								<List.Item
									onClick={() => {
										setIsMoreSettingsOpen(false);
										conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL);
										conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL, false);
									}}>
									<StreamSettingsIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
									{translate('streamSettingsModalTitle')}
								</List.Item>
								{localParticipant.localTrackController.tracks[Cam] && !isMobileOrTablet() && (
									<List.Item
										onClick={() => {
											setIsMoreSettingsOpen(false);
											conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL);
											conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL, false);
										}}>
										<SelectBackgroundIcon
											color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
											width={24}
											height={25}
										/>
										{translate('selectBackground')}
									</List.Item>
								)}
								{!isPatientView && (
									<>
										{haveHelloParticipant && shouldShowLiveCaptionsButton() && (
											<List.Item
												onClick={() => {
													setIsMoreSettingsOpen(false);
													conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_LIVE_CAPTIONS);
												}}>
												{conferenceConfigurations.isLiveCaptionsOpen && (
													<LiveCaptionsIcon
														color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
														width={24}
														height={25}
													/>
												)}
												{!conferenceConfigurations.isLiveCaptionsOpen && (
													<LiveCaptionsDisabledIcon
														color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
														width={24}
														height={25}
													/>
												)}
												{intl.formatMessage({
													id: conferenceConfigurations.isLiveCaptionsOpen ? 'disableLiveCaptions' : 'enableLiveCaptions',
												})}
											</List.Item>
										)}
										{!screenType.isSmall && (
											<List.Item
												onClick={() => conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_FIT_SCREEN)}>
												{conferenceConfigurations.isFitScreen && (
													<ExpandIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} width={24} height={25} />
												)}
												{!conferenceConfigurations.isFitScreen && (
													<CollapseIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} width={24} height={25} />
												)}
												{intl.formatMessage({ id: conferenceConfigurations.isFitScreen ? 'fullScreen' : 'fitToScreen' })}
											</List.Item>
										)}
										<List.Item
											onClick={() => conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW)}>
											{!conferenceConfigurations.isGridView && (
												<GridViewIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
											)}
											{conferenceConfigurations.isGridView && (
												<NonGridViewIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
											)}
											{translate(!conferenceConfigurations.isGridView ? 'toggleGridView' : 'toggleLayoutView')}
										</List.Item>
									</>
								)}
							</List>
						</Dropdown.Items>
					</Dropdown>
				</Tooltip>
			)}
		</>
	);
};

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 * @param {React.Dispatch<React.SetStateAction<any>>} props.setCanScreenShare
 * @param {import('calls/RemoteParticipant.js').default[]} props.transferrableParticipants
 */
const CallControlsView = ({ mainParticipant, setCanScreenShare, transferrableParticipants }) => {
	const intl = useIntl();
	const conference = useConference();
	const conferenceState = useConferenceState();
	const conferenceConfigurations = useConferenceConfigurations();
	const socketFunctions = useContext(SocketFunctionsContext);
	const localParticipant = useLocalParticipant();
	const localParticipantTracks = useControllerTracks(localParticipant.localTrackController);
	const mainParticipantTracks = useControllerTracks(
		mainParticipant instanceof LocalParticipant ? mainParticipant.localTrackController : mainParticipant.remoteTrackController
	);
	const screenType = useScreenType();
	const [isPatientView] = useState(conference.callType === enums.CallTypes.SECURITYCAM);
	const [sessionIdMessage, setSessionIdMessage] = useState(intl.formatMessage({ id: 'copySessionId' }));
	const camStatus = useRef(null);
	const micStatus = useRef(null);
	const canLeaveConference = conferenceState instanceof Conference.StateInitiated;
	const deviceState = useHelloDeviceState(mainParticipant);
	const { roundingConfigurations } = conferenceConfigurations;
	const isSomeoneElseSharing =
		!localParticipant.localTrackController.tracks[ScreenShare] &&
		[...conference.participants.values()].some(
			p => p.remoteTrackController.tracks[ScreenShare] && !(p instanceof RemoteHelloParticipant)
		);

	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const userSettings = useSelector(state => state.configurations.userSettings);
	const userSession = useSelector(state => state.user.userSession);
	const dispatch = useDispatch();

	useEffect(() => {
		const bindMediaEvents = async () => {
			camStatus.current = await checkForPermission(MediaTypes.CAMERA);
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			camStatus.current.onchange = onDevicePermissionChange;
			micStatus.current.onchange = onDevicePermissionChange;
		};

		const onDevicePermissionChange = res => {
			if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
				dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
			}
		};

		bindMediaEvents();
	}, [dispatch]);

	const handleLocalCameraErrors = error => {
		if (error?.error?.name === UserPermissionDeniedErrors.NotFoundError) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: 'modal',
					type: StreamError.CAMERA_NOT_FOUND.type,
				})
			);
		} else if (error?.error?.name === UserPermissionDeniedErrors.NotAllowedError) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.CAMERA_BLOCKED.type : StreamError.CAMERA_BLOCKED_GENERIC.type,
				})
			);
		} else {
			conferenceConfigurations.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
		}
	};

	const shouldShowCameraControlButton = () =>
		!conferenceConfigurations.isGridView &&
		mainParticipant instanceof RemoteHelloParticipant &&
		!localParticipant.isGuest &&
		![UserRoles.PATIENT, UserRoles.VISITOR].includes(getUserRole()) &&
		deviceState;

	const shouldShowMultipartyCallButton = () =>
		!screenType.isSmall &&
		!isPatientView &&
		(conferenceConfigurations.isEmbeddedView ||
			(!localParticipant.isGuest &&
				UserRoles.VISITOR !== getUserRole() &&
				getSomeRoleConfigurationsValues(roundingConfigurations, [
					RoundingSettings.MeetingUrl,
					RoundingSettings.InviteViaSms,
					RoundingSettings.DialIn,
					RoundingSettings.DialOut,
					RoundingSettings.TranslationServices,
					RoundingSettings.InviteViaEmail,
				])));

	const isMedicalInfoConfigsOn = () =>
		getSomeRoleConfigurationsValues(roundingConfigurations, [
			RoundingSettings.PatientDiagnoses,
			RoundingSettings.PatientProcedures,
			RoundingSettings.PatientNotes,
			RoundingSettings.PatientPrescriptions,
			RoundingSettings.PatientPhysicalTherapy,
		]);

	const closeLiveExaminationView = () => {
		if (conferenceConfigurations.isLiveExaminationOpen) {
			socketFunctions.toggleHealthData({
				isEnabled: false,
				helloDeviceId: mainParticipant.objectId,
				conferenceId: conference.conferenceId,
				participantId: localParticipant.id,
				toolType: SocketEvents.HelloDevice.TOOLTYPE_LIVE_EXAMINATION,
				measurementType: '',
			});
		}
	};

	const getToolTipMessage = () => {
		if (isSomeoneElseSharing) {
			return 'screenShareNotAllowed';
		}
		if (localParticipant.localTrackController.tracks[ScreenShare]) {
			return 'stopScreenSharing';
		}
		return 'startScreenSharing';
	};

	const shareScreen = async evt => {
		evt.stopPropagation();
		try {
			if (localParticipant.localTrackController.tracks[ScreenShare]) {
				await localParticipant.localTrackController.remove([ScreenShare, ScreenAudio]);
				setCanScreenShare(prevState => {
					return {
						...prevState,
						[localParticipant.id]: true,
					};
				});
			} else {
				const tracks = await conference.participantFactory.trackFactory.createTracks([ScreenShare, ScreenAudio]);
				if ([...conference.participants.values()].some(p => p.remoteTrackController.tracks[ScreenShare])) {
					tracks.forEach(track => track.stop());
					setCanScreenShare(prevState => {
						return {
							...prevState,
							[localParticipant.id]: false,
						};
					});
					return;
				}
				const trackTypes = tracks.map(t => t.type);
				await localParticipant.localTrackController.add(trackTypes);
			}
		} catch (error) {
			if (error instanceof ErrorCanceled) {
				return;
			}
			conferenceConfigurations.setConferenceErrorMessages([
				{
					id: uuidv4(),
					message: translate('somethingWentWrong'),
				},
			]);
		}
	};

	return (
		<CallControls isRightToLeft={conferenceConfigurations.isRightToLeft} isDarkMode={conferenceConfigurations.isDarkMode}>
			<CallControls.Info>
				<div>
					<StyledInfo $isDarkMode={conferenceConfigurations.isDarkMode}>
						{conference.conferenceId && !screenType.isSmall && (
							<Tooltip text={sessionIdMessage} position='top-left'>
								<IconButton
									background={null}
									color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
									onClick={evt => {
										evt.stopPropagation();
										navigator.clipboard.writeText(conference.conferenceId);
										setSessionIdMessage(intl.formatMessage({ id: 'copiedSessionId' }));
										setTimeout(() => {
											setSessionIdMessage(intl.formatMessage({ id: 'copySessionId' }));
										}, 2000);
									}}>
									{!screenType.isSmall && (
										<ContentCopyIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
									)}
								</IconButton>
							</Tooltip>
						)}
						<Duration startTime={conference.startTime} />
					</StyledInfo>
					{![UserRoles.VISITOR, UserRoles.GUEST, UserRoles.PATIENT].includes(getUserRole()) && <PatientInformation />}
					{getUserRole() === UserRoles.NURSE && <CallEventInformation />}
				</div>
			</CallControls.Info>
			<CallControls.Controls>
				{!isPatientView && (
					<>
						{!screenType.isSmall && (
							<Tooltip text={intl.formatMessage({ id: getToolTipMessage() })}>
								<IconButton
									disabled={isSomeoneElseSharing}
									background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
									color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
									borderColor={LightTheme.colors.grayTen02}
									onClick={shareScreen}>
									{localParticipantTracks[ScreenShare] && (
										<ScreenshareIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
									)}
									{!localParticipantTracks[ScreenShare] && (
										<ScreenshareOffIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
									)}
								</IconButton>
							</Tooltip>
						)}
						<Tooltip
							text={intl.formatMessage({ id: localParticipantTracks[Cam] ? 'turnCameraOff' : 'turnCameraOn' })}
							mobileTitle={intl.formatMessage({ id: localParticipantTracks[Cam] ? 'stop' : 'cam' })}>
							<IconButton
								background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
								color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
								borderColor={LightTheme.colors.grayTen02}
								onClick={async evt => {
									evt.preventDefault();
									evt.stopPropagation();
									try {
										const callBackground = userSettings[UserSettings.CALL_BACKGROUND];
										let selectedCallBackground = '';
										if (!localParticipant.localTrackController.tracks[Cam]) {
											selectedCallBackground = await getSelectedBackgroundImage(userSettings, visualSettings);
										}
										const params = await getUserBackgroundParams({
											localParticipant,
											userSettings,
											visualSettings,
											healthSystemId: userSession.healthSystem.id,
											backgroundImages: conferenceConfigurations.backgroundImages,
											selectedCallBackground: selectedCallBackground || callBackground,
										});

										if (
											(selectedCallBackground || callBackground) &&
											!localParticipant.localTrackController.tracks[Cam] &&
											!isMobileOrTablet()
										) {
											changeLocalParticipantBackground(params);
										} else {
											await localParticipant.localTrackController.toggle(Cam);
										}
									} catch (error) {
										handleLocalCameraErrors(error);
									}
								}}>
								{localParticipantTracks[Cam] && <VideocamIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />}
								{!localParticipantTracks[Cam] && (
									<VideocamOffIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
								)}
							</IconButton>
						</Tooltip>
					</>
				)}
				{isPatientView && mainParticipant instanceof RemoteHelloParticipant && <LocalParticipantMic />}
				{shouldShowCameraControlButton() && (
					<Tooltip
						text={intl.formatMessage({ id: conferenceConfigurations.isCameraControlsOpen ? 'hidePTZ' : 'showPTZ' })}
						mobileTitle={intl.formatMessage({ id: conferenceConfigurations.isCameraControlsOpen ? 'hidePTZ' : 'showPTZ' })}>
						<IconButton
							background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
							color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
							borderColor={LightTheme.colors.grayTen02}
							onClick={() => {
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_CAMERA_MEASUREMENTS, false);
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_PARTICIPANTS_VIEW, false);
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_CAMERA_CONTROLS);
							}}>
							{conferenceConfigurations.isCameraControlsOpen && (
								<ControlCameraIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
							)}
							{!conferenceConfigurations.isCameraControlsOpen && (
								<ControlCameraOffIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />
							)}
						</IconButton>
					</Tooltip>
				)}
				<Tooltip text={intl.formatMessage({ id: 'endCall' })} mobileTitle={intl.formatMessage({ id: 'end' })}>
					<IconButton
						id='stress-test-end-call-button'
						background={LightTheme.colors.redOne}
						color={LightTheme.colors.grayZero}
						onClick={evt => {
							evt.stopPropagation();
							if (!canLeaveConference) {
								return;
							}
							if (transferrableParticipants.length > 0 && localParticipant.isOwner) {
								conferenceConfigurations.setIsTransferOwnershipModalopen(true);
							} else {
								conference.leave();
								conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
							}
						}}>
						<CallEndIcon />
					</IconButton>
				</Tooltip>
				{!isPatientView && <LocalParticipantMic />}
				{isPatientView && mainParticipant instanceof RemoteHelloParticipant && (
					<Tooltip
						text={intl.formatMessage({ id: mainParticipantTracks[Mic] ? 'muteAudio' : 'unMuteAudio' })}
						mobileTitle={intl.formatMessage({ id: mainParticipantTracks[Mic] ? 'mute' : 'audio' })}>
						<IconButton
							background={getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)}
							color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
							borderColor={LightTheme.colors.grayTen02}
							onClick={evt => {
								evt.stopPropagation();
								mainParticipant.remoteTrackController.toggle(Mic);
							}}>
							{mainParticipantTracks[Mic] && <VolumeUpIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />}
							{!mainParticipantTracks[Mic] && <VolumeOffIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} />}
						</IconButton>
					</Tooltip>
				)}
				{shouldShowMultipartyCallButton() && (
					<Tooltip text={intl.formatMessage({ id: 'invitePeople' })} mobileTitle={intl.formatMessage({ id: 'invite' })}>
						<IconButton
							id='stress-test-add-people-button'
							background={
								conferenceConfigurations.isInviteParticipantsModalViewOpen
									? LightTheme.colors.blueFive
									: getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)
							}
							color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
							borderColor={
								conferenceConfigurations.isInviteParticipantsModalViewOpen
									? LightTheme.colors.blueOne05
									: LightTheme.colors.grayTen02
							}
							onClick={evt => {
								evt.stopPropagation();
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL, false);
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL, false);
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL);
							}}>
							<InviteUserIcon
								color={
									conferenceConfigurations.isInviteParticipantsModalViewOpen
										? LightTheme.colors.blueFour
										: getCallsButtonColor(conferenceConfigurations.isDarkMode)
								}
							/>
						</IconButton>
					</Tooltip>
				)}
				{conferenceConfigurations.isEmbeddedView && (
					<Tooltip
						text={intl.formatMessage({ id: conferenceConfigurations.isMinimizedView ? 'maximizeFeed' : 'minimizeFeed' })}
						mobileTitle={intl.formatMessage({ id: conferenceConfigurations.isMinimizedView ? 'fullScreen' : 'exitFullScreen' })}>
						<IconButton
							background={
								conferenceConfigurations.isMinimizedView
									? LightTheme.colors.blueFive
									: getCallsControlsButtonBackground(conferenceConfigurations.isDarkMode)
							}
							borderColor={conferenceConfigurations.isMinimizedView ? LightTheme.colors.blueOne05 : LightTheme.colors.grayTen02}
							color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
							onClick={evt => {
								evt.stopPropagation();
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_MINIMIZED_VIEW);
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW, true);
							}}>
							{conferenceConfigurations.isMinimizedView && (
								<MaximizeIcon
									color={
										conferenceConfigurations.isMinimizedView
											? LightTheme.colors.blueFour
											: getCallsButtonColor(conferenceConfigurations.isDarkMode)
									}
								/>
							)}
							{!conferenceConfigurations.isMinimizedView && (
								<MinimizeIcon
									color={
										conferenceConfigurations.isMinimizedView
											? LightTheme.colors.blueFour
											: getCallsButtonColor(conferenceConfigurations.isDarkMode)
									}
								/>
							)}
						</IconButton>
					</Tooltip>
				)}
				<MoreSettingsButton mainParticipant={mainParticipant} />
			</CallControls.Controls>
			<CallControls.OtherControls>
				{!conferenceConfigurations.isEmbeddedView && (
					<div>
						{!localParticipant.isGuest && isMedicalInfoConfigsOn() && mainParticipant?.role !== UserTypes.GUEST && (
							<MedicalInfoButton closeLiveExaminationView={closeLiveExaminationView} />
						)}
						{!isPatientView &&
							!localParticipant.isGuest &&
							[UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole()) &&
							conferenceConfigurations.showPatientMeasurementsButtons &&
							mainParticipant?.role !== UserTypes.GUEST && (
								<PatientMeasurementsButtons closeLiveExaminationView={closeLiveExaminationView} />
							)}
						{mainParticipant instanceof RemoteHelloParticipant &&
							getRoleConfigurationValue(roundingConfigurations, RoundingSettings.TVControls) &&
							!localParticipant.isGuest &&
							UserRoles.VISITOR !== getUserRole() && <HelloDeviceControls helloParticipant={mainParticipant} />}
					</div>
				)}
			</CallControls.OtherControls>
		</CallControls>
	);
};

export default CallControlsView;
