import { useState } from 'react';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference } from 'calls/hooks/index.js';
import { ButtonType } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import { dialOutTranslator } from 'api/sipProvider.js';
import CustomSelect from 'components/Select.jsx';
import { participant as CallsParticipant } from '@solaborate/calls';

/**
 * @param {object} props
 * @param {(err) => void} props.setError
 * @param {(err) => void} props.onDismiss
 * @param {object} props.interpretationConfig
 */

const InviteAmnView = ({ onDismiss, setError, interpretationConfig }) => {
	const conference = useConference();
	const intl = useIntl();
	const callEnums = { VideoAndAudio: 1, AudioOnly: 2 };
	const callTypes = [
		{ id: callEnums.VideoAndAudio, name: intl.formatMessage({ id: 'videoAndAudioOption' }) },
		{ id: callEnums.AudioOnly, name: intl.formatMessage({ id: 'audioOnlyOption' }) },
	];
	const [selectedCallTypeId, setSelectedCallTypeId] = useState(
		interpretationConfig?.callType ? callTypes.find(item => interpretationConfig.callType === item.id).id : callTypes[0].id
	);

	const removeParticipantOnNoAnswer = (participantId, message = '') => {
		setTimeout(() => {
			const participant = conference.participants.get(participantId);
			const isParticipantConnecting = participant && participant.state instanceof CallsParticipant.StateConnecting;
			if (isParticipantConnecting) {
				conference.removeParticipant(participantId);
				onDismiss(message);
			}
		}, 60000);
	};

	const inviteDialOutTranslator = async () => {
		const response = await dialOutTranslator(
			interpretationConfig.sipEndpoint,
			conference.conferenceId,
			selectedCallTypeId === callEnums.AudioOnly
		);
		if (response.error) {
			setError(translate('serviceProvicerNoAnswer'));
		} else {
			removeParticipantOnNoAnswer(response.participantId, intl.formatMessage({ id: 'unableToConnectInterpreter' }));
			onDismiss();
		}
	};

	return (
		<>
			<Modal.Content>
				<div className='translation-services'>
					<div className='interpreter-desc'>
						<p>{translate('inviteInterpreterDesc')}</p>
					</div>
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label={intl.formatMessage({ id: 'callType' })}
							name='selectCallType'
							items={callTypes}
							valueField='id'
							textField='name'
							placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'callType' })}`}
							value={selectedCallTypeId}
							onSelect={event => setSelectedCallTypeId(+event.target.value)}
						/>
					</div>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button type='submit' onClick={inviteDialOutTranslator} variant={ButtonType.SUBMIT}>
					{translate('inviteInterpreter')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default InviteAmnView;
