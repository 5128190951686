import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { conference as Conference, enums, participant as Participant } from '@solaborate/calls';
import {
	Mic,
	Cam,
	ScreenShare,
	RemoteTrackAdded,
	RemoteTrackRemoved,
	LocalTrackAdded,
	LocalTrackRemoved,
} from '@solaborate/calls/webrtc';
import ConferenceProvider from 'calls/ConferenceProvider.jsx';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import {
	useConference,
	useConferenceConfigurations,
	useConferenceParticipants,
	useConferenceState,
	useControllerTracks,
	useLocalParticipant,
} from 'calls/hooks/index.js';
import { ConferenceEndReasonMessages, ControlsActions, UserRoles, UserTypes } from 'calls/enums/index.js';
import {
	callTypeToTrackTypes,
	changeLocalParticipantBackground,
	findPatientRoleParticipant,
	findRemoteHelloParticipant,
	getCallsControlsButtonBackground,
} from 'calls/helpers/index.js';
import { Button, Duration, IconButton, Modal, Tooltip } from 'calls/components/index.js';
import {
	CallEndIcon,
	VideocamIcon,
	VideocamOffIcon,
	MicIcon,
	MicOffIcon,
	InviteUserIcon,
	MaximizeIcon,
	MinimizeIcon,
} from 'calls/icons/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import {
	ParticipantsView,
	InviteParticipantsView,
	MainParticipantView,
	CallControlsView,
	StreamSettingsView,
	VirtualBackgroundView,
} from 'calls/views/index.js';
import { VirtualBackgroundProvider } from 'calls/views/VirtualBackgroundProvider.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import {
	fetchVirtualBackgroundImages,
	getCallsButtonColor,
	getSelectedBackgroundImage,
	getUserBackgroundParams,
} from 'infrastructure/helpers/commonHelpers.js';
import { busySound, dropSound, stopOutgoingCallSound } from 'components/CallSounds.jsx';
import RemoveParticipantModal from 'components/Modal.jsx';
import { ObjectType, UserPermissionDeniedErrors, WindowSize } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { UserSettings } from 'constants/configurationEnums.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isMinimizedView: boolean, $isDarkMode: boolean }, never>}
 */
const StyledObserverConference = styled.div`
	position: fixed;
	bottom: ${LightTheme.spacing[7]}px;
	left: ${LightTheme.spacing[5]}px;
	padding: ${LightTheme.spacing[3]}px;
	width: 500px;
	background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayTen)};
	z-index: 1000;
	box-shadow:
		rgba(0, 0, 0, 0.09) 0px 2px 1px,
		rgba(0, 0, 0, 0.09) 0px 4px 2px,
		rgba(0, 0, 0, 0.09) 0px 8px 4px,
		rgba(0, 0, 0, 0.09) 0px 16px 8px,
		rgba(0, 0, 0, 0.09) 0px 32px 16px;

	p {
		margin: 0;
		padding: 0;
		line-height: normal;
		${({ $isMinimizedView }) => $isMinimizedView && `color: ${LightTheme.colors.grayNinetyEight};`}
	}

	> footer {
		display: flex;
		align-items: center;

		> div {
			margin-left: auto;
		}
	}

	+ div {
		> div {
			z-index: 9999;
		}
	}

	${props =>
		!props.$isMinimizedView &&
		css`
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			padding: 0;
			border-radius: 0;
			overflow: hidden;

			> main,
			> aside {
				height: calc(100vh - 60px);
			}

			> main {
				width: 100%;
			}

			> aside {
				width: fit-content;
				margin: 0 auto;
				display: flex;
				align-items: center;
				overflow: hidden;
			}
		`}
`;

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, $isViewPatient:boolean, $isMinimizedView: boolean, $gridCount: number, $isCallSettingsPanelOpen: boolean, $isMoreParticipantsViewOpen: boolean}, never>}
 */
const StyledLayoutCallModal = styled.div`
	width: 100%;
	padding-bottom: var(--spacing-s);
	background: ${({ $isDarkMode }) => ($isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayTen)};
	overflow: hidden;
	${({ $isMinimizedView }) => !$isMinimizedView && 'height: calc(100vh - 60px);'}

	.participants-wrapper {
		height: 100%;
		width: fit-content;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&:not(:has(> aside)) > main {
			width: calc(16 / 9 * 100vh);
		}

		${({ $isMinimizedView, $gridCount, $isCallSettingsPanelOpen, $isMoreParticipantsViewOpen }) =>
			!$isMinimizedView &&
			css`
				${$gridCount <= 2 && 'position: relative;'}
				${($isCallSettingsPanelOpen || $isMoreParticipantsViewOpen) && 'width: 70%;'}
			`}

		> main {
			width: calc((16 / 9) * (100vh - 170px));
			max-width: 100vw;
			height: calc((9 / 16) * 100vw);
			max-height: calc(100vh - 170px);
			background: ${({ $isDarkMode }) => ($isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayFourteen)};
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			@media (min-height: 700px) {
				width: calc((16 / 9) * (100vh - 190px));
				max-height: calc(100vh - 190px);
			}

			@media (min-height: 800px) {
				width: calc((16 / 9) * (100vh - 200px));
				max-height: calc(100vh - 200px);
			}

			&:not(:has(> video)) {
				display: flex;
				-webkit-justify-content: center;
				-webkit-align-items: center;
			}

			${({ $gridCount }) =>
				$gridCount <= 2 &&
				css`
					width: calc((16 / 9) * (100vh - 65px)) !important;
					max-height: calc(100vh - 65px) !important;
				`}
		}

		${({ $isMinimizedView }) =>
			$isMinimizedView &&
			css`
				width: 100%;
				> aside {
					width: 100%;
					> main {
						width: 100% !important;
						height: 100% !important;
					}
				}
			`}

		${({ $isCallSettingsPanelOpen, $isMoreParticipantsViewOpen }) =>
			($isCallSettingsPanelOpen || $isMoreParticipantsViewOpen) &&
			css`
				@media (min-width: ${WindowSize.TABLET}px) {
					align-items: center;
					justify-content: center;
					margin: 0;
					> main {
						width: calc(16 / 9 * 100dvh);
						max-width: 70dvw;
						height: calc(9 / 16 * 70dvw);
						max-height: 100dvh;
					}
				}
			`}

    ${({ $isMinimizedView, $isViewPatient }) =>
			$isMinimizedView &&
			$isViewPatient &&
			css`
				> main aside {
					> header,
					> aside {
						transform: scale(0.8);
						transform-origin: top left;
						margin-left: -10px;
					}
					aside:first-of-type {
						margin-top: -5px;
					}
					aside:nth-of-type(2) {
						margin-top: -20px;
					}
				}
			`}
	}

	@media (max-width: ${WindowSize.TABLET}px) {
		height: 100%;
		padding-bottom: 0;

		.participants-wrapper {
			width: 100%;
			height: calc(100% - 100px);
			overflow: auto;

			> main {
				width: 100%;
				height: auto;
				aspect-ratio: 16 / 9;
			}
		}
	}
`;

const StyledMinimizedCallControl = styled.section`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${LightTheme.spacing[1]}px;
	width: 100%;
	margin-top: 20px;
	> div {
		display: flex;
		gap: 10px;
	}
`;

/**
 * @param {object} props
 * @param {object[]} props.participantsToCall
 */
const InitiatingConference = ({ participantsToCall }) => {
	const conferenceConfigs = useConferenceConfigurations();
	const conference = useConference();
	const [message, setMessage] = useState('');
	const intl = useIntl();

	useEffect(() => {
		let startConferenceMessage = `${intl.formatMessage({ id: 'calling' })}`;
		if (participantsToCall?.length === 1 && participantsToCall[0].objectType === enums.ObjectTypes.USER) {
			startConferenceMessage = `${startConferenceMessage} ${participantsToCall[0].firstName} ${participantsToCall[0].lastName}`;
		} else if (participantsToCall?.length > 1 && conference.callType === enums.CallTypes.FIRST_RESPONDER) {
			startConferenceMessage = `${startConferenceMessage} ${intl.formatMessage(
				{ id: 'pluralRoleName' },
				{ value: conferenceConfigs.customDisplayNames.nurseDisplayName }
			)}`;
		} else if (conference.conferenceName && conference.callType === enums.CallTypes.SECURITYCAM) {
			startConferenceMessage = `${intl.formatMessage({ id: 'viewing' })} ${conference.conferenceName}`;
		} else if (conference.conferenceName) {
			startConferenceMessage = `${startConferenceMessage} ${conference.conferenceName}`;
		} else {
			startConferenceMessage = `${intl.formatMessage({ id: 'initiatingCall' })}`;
		}
		setMessage(startConferenceMessage);
	}, [conference, intl, participantsToCall]);

	return <>{message}</>;
};

/**
 * @param {object} props
 * @param {object[]} props.participantsToCall
 * @param {boolean} [props.permissionsDenied]
 */
const ConferenceEnded = ({ participantsToCall, permissionsDenied }) => {
	const conferenceConfigs = useConferenceConfigurations();
	const conference = useConference();
	const conferenceState = useConferenceState();

	const [message, setMessage] = useState('');

	useEffect(() => {
		if (conferenceState instanceof Conference.StateEnded) {
			let endReason = ConferenceEndReasonMessages[conferenceState.endReason];

			if (!endReason) {
				setMessage(permissionsDenied ? translate('allowPermissions') : endReason);
				return;
			}

			if (
				conference.callType === enums.CallTypes.FIRST_RESPONDER &&
				(enums.ConferenceEndReasons.PARTICIPANT_OFFLINE === conferenceState.endReason ||
					enums.ConferenceEndReasons.PARTICIPANT_BUSY === conferenceState.endReason)
			) {
				if (participantsToCall?.length === 1) {
					endReason = translate(
						enums.ConferenceEndReasons.PARTICIPANT_OFFLINE === conferenceState.endReason
							? 'deviceOfflineTryLaterExtended'
							: 'deviceOnCallTryLaterExtended',
						{ value1: `${participantsToCall[0].firstName} ${participantsToCall[0].lastName} ` }
					);
				} else {
					endReason = translate('noSomethingsAvailableTryAgainLater', {
						value: conferenceConfigs.customDisplayNames.nurseDisplayName,
					});
				}
			}
			setMessage(endReason);
		}
	}, [conference, conferenceState, participantsToCall]);

	return <>{message}</>;
};

/**
 * @param {object} props
 * @param {object[]} props.participantsToCall
 * @param {() => void} props.onCallEnded
 * @param {() => void} props.onCallStarted
 * @param {boolean} props.isEmergencyCallOpen
 * @param {boolean} [props.isJoin=false]
 * @param {string} props.roomId
 */
const ConferenceModalComponent = props => {
	const { participantsToCall, isJoin, roomId, isDarkMode } = props;
	const intl = useIntl();
	const conference = useConference();
	const conferenceState = useConferenceState();
	const conferenceParticipants = useConferenceParticipants();
	const localParticipant = useLocalParticipant();
	const localTracks = useControllerTracks(localParticipant.localTrackController);
	const conferenceConfigs = useConferenceConfigurations();
	const [isInitiating, setIsInitiating] = useState(true);
	const [permissionsDenied, setPermissionsDenied] = useState(false);
	const userSession = useSelector(state => state.user.userSession);
	const afterConferenceEndedTimeoutRef = useRef(null);
	const mainParticipantVideoRef = useRef(null);

	/**
	 * @typedef {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} Participant
	 * @type {[Participant, import('react').Dispatch<import('react').SetStateAction<Participant>>]}
	 */
	const [mainParticipant, setMainParticipant] = useState(conference.localParticipant);
	const [callStarted, setCallStarted] = useState(false);
	const [activeTrackType, setActiveTrackType] = useState(Cam);
	const [canScreenShare, setCanScreenShare] = useState(null);
	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const userSettings = useSelector(state => state.configurations.userSettings);

	useEffect(() => {
		const onLocalTrackChanged = trackEvent => {
			if (trackEvent instanceof LocalTrackAdded) {
				if (trackEvent.track.type === ScreenShare) {
					conferenceConfigs.setGridCount(prevState => prevState + 1);
				}

				if (trackEvent.track.type === Mic) {
					const existingLocalParticipant = conferenceConfigs.speechToTextParticipants.find(
						participant => participant.id === localParticipant.id
					);
					if (!existingLocalParticipant) {
						conferenceConfigs.pushSpeechToTextParticipant(localParticipant);
					}
				}
			}

			if (trackEvent instanceof LocalTrackRemoved) {
				if (trackEvent.type === ScreenShare) {
					if (localParticipant === mainParticipant) {
						setActiveTrackType(Cam);
					}
					conferenceConfigs.setGridCount(prevState => prevState - 1);
				}

				if (trackEvent.type === Mic) {
					conferenceConfigs.removeSpeechToTextParticipant(localParticipant);
				}
			}
		};

		return localParticipant.localTrackController.on(onLocalTrackChanged);
	}, [conferenceConfigs, localParticipant, mainParticipant]);

	useEffect(() => {
		return conference.on(event => {
			const participantListeners = new Map();
			const helloParticipant = findRemoteHelloParticipant(conference.participants);
			const patientParticipant = findPatientRoleParticipant(conference.participants);
			const mainScreenParticipant = helloParticipant || patientParticipant;

			if (event instanceof Conference.ParticipantAdded) {
				conferenceConfigs.setGridCount(prevState => prevState + 1);

				if (
					conference.participants.size > 1 &&
					mainScreenParticipant &&
					!conferenceConfigs.pinnedParticipantId &&
					activeTrackType !== ScreenShare
				) {
					setMainParticipant(mainScreenParticipant);
					setActiveTrackType(Cam);
				}
				const onParticipantStateChanged = state => {
					if (state instanceof Participant.StateConnected) {
						setCallStarted(true);
						if (conference.participants.size === 1) {
							stopOutgoingCallSound();
							setMainParticipant(event.participant);
							setActiveTrackType(Cam);
						}
						if (conference.participants.size > 1 && mainScreenParticipant && canScreenShare) {
							stopOutgoingCallSound();
							setMainParticipant(mainScreenParticipant);
							setActiveTrackType(Cam);
						}
					}

					if (state instanceof Participant.HelloDeviceStateChanged) {
						const { isCameraPrivacyOn } = event.participant.deviceState;
						if (getUserRole() === UserRoles.VISITOR && isCameraPrivacyOn) {
							// familymember
							conference.leave();
							let endReason;
							if (state instanceof Participant.MicPrivacyStateChanged || state instanceof Participant.CameraPrivacyStateChanged) {
								endReason = enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR;
							} else {
								endReason = enums.ConferenceEndReasons.PARTICIPANT_DECLINED;
							}
							conference.close(endReason);
						}
					}
				};

				const onRemoteTrackChanged = trackEvent => {
					const sortedParticipants = [localParticipant, ...conference.participants.values()]
						.filter(p => !(p instanceof RemoteHelloParticipant))
						.sort((a, b) => a.objectId - b.objectId);

					if (trackEvent instanceof RemoteTrackAdded) {
						if (trackEvent.track.type === ScreenShare) {
							conferenceConfigs.setGridCount(prevState => prevState + 1);
						}
						if (trackEvent.track.type !== Mic && !(event.participant instanceof RemoteHelloParticipant)) {
							if (trackEvent.track.type === ScreenShare && !conferenceConfigs.pinnedParticipantIdRef.current) {
								setMainParticipant(event.participant);
								setActiveTrackType(ScreenShare);
							}

							if (trackEvent.track.type === Cam && activeTrackType === ScreenShare) {
								setActiveTrackType(ScreenShare);
							}
						}
						if (
							localParticipant.localTrackController.tracks[ScreenShare] &&
							trackEvent.track.type === ScreenShare &&
							!(event.participant instanceof RemoteHelloParticipant)
						) {
							sortedParticipants.forEach(p => {
								if (localParticipant.id !== sortedParticipants[0].id) {
									setCanScreenShare({
										...canScreenShare,
										[p.id]: false,
									});
									p.localTrackController.remove(ScreenShare);
								}
							});
						}
						if (trackEvent.track.type === Mic) {
							const existingRemoteParticipant = conferenceConfigs.speechToTextParticipants.find(
								participant => participant.id === event.participant.id
							);
							if (!existingRemoteParticipant) {
								conferenceConfigs.pushSpeechToTextParticipant(event.participant);
							}
						}
					}
					if (trackEvent instanceof RemoteTrackRemoved) {
						if (trackEvent.type === ScreenShare) {
							if (!conferenceConfigs.pinnedParticipantIdRef.current) {
								if (mainScreenParticipant) {
									setMainParticipant(mainScreenParticipant);
								}
								setActiveTrackType(Cam);
							}
							conferenceConfigs.setGridCount(prevState => prevState - 1);
						}
						if (trackEvent.type === Mic) {
							conferenceConfigs.removeSpeechToTextParticipant(event.participant);
						}
					}
				};
				const unbindParticipantListeners = event.participant.on(onParticipantStateChanged);
				const unbindParticipantTrackListeners = event.participant.remoteTrackController.on(onRemoteTrackChanged);
				participantListeners.set(event.participant, () => {
					unbindParticipantListeners();
					unbindParticipantTrackListeners();
				});
			} else if (event instanceof Conference.ParticipantRemoved) {
				conferenceConfigs.setGridCount(prevState => prevState - 1);
				const { participant } = event;
				if (!(event.participant instanceof RemoteHelloParticipant) || event.participant.objectType !== ObjectType.SIP_USER) {
					transferrableParticipants.filter(p => p.id !== participant.id);
				}
				setMainParticipant(prevState => {
					if (participant !== prevState) {
						return prevState;
					}

					setActiveTrackType(Cam);
					if (conference.participants.size > 0) {
						return [...conference.participants.values()].reverse()[0];
					}

					return conference.localParticipant;
				});

				if (participantListeners.has(participant)) {
					participantListeners.get(participant)();
				}
			}
		});
	}, [conference, conferenceConfigs, localParticipant, activeTrackType, canScreenShare]);

	useEffect(() => {
		return conference.on(event => {
			if (event instanceof Conference.StateEnded) {
				conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_MINIMIZED_VIEW, true);
				const playConferenceEndedSound = async () => {
					switch (event.endReason) {
						case enums.ConferenceEndReasons.PARTICIPANT_OFFLINE:
						case enums.ConferenceEndReasons.PARTICIPANT_BUSY:
						case enums.ConferenceEndReasons.PARTICIPANT_NOT_ANSWERING:
						case enums.ConferenceEndReasons.PARTICIPANT_DECLINED:
						case enums.ConferenceEndReasons.HAS_ACTIVE_CONFERENCE:
						case enums.ConferenceEndReasons.FAILED_TO_GET_INITIATOR_INFO:
						case enums.ConferenceEndReasons.PARTICIPANT_INVITE_FAILED:
						case enums.ConferenceEndReasons.PARTICIPANT_INVITE_DENIED:
							stopOutgoingCallSound();
							await busySound();
							break;
						case enums.ConferenceEndReasons.OWNER_LEFT:
						case enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR:
							stopOutgoingCallSound();
							await dropSound();
							break;
						default:
							stopOutgoingCallSound();
							break;
					}
				};
				playConferenceEndedSound();
			}
		});
	}, [conference]);

	useEffect(() => {
		setPermissionsDenied(false);
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_MINIMIZED_VIEW, true);
		if (conference.callType !== enums.CallTypes.SECURITYCAM) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW, true);
		}

		conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_EMBEDDED_VIEW, true);
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.IS_DARK_MODE, isDarkMode);
		setIsInitiating(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conference.callType, isDarkMode]);

	useEffect(() => {
		if (conference.callType === enums.CallTypes.SECURITYCAM) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW, false);
		}
	}, [conference.callType, conferenceConfigs]);

	const startConference = async () => {
		if (!(conference.state instanceof Conference.StateInitialized)) {
			return;
		}

		await conference.socket.authPromise;
		const callBackground = userSettings[UserSettings.CALL_BACKGROUND];
		const selectedCallBackground = await getSelectedBackgroundImage(userSettings, visualSettings);

		try {
			const params = await getUserBackgroundParams({
				localParticipant,
				userSettings,
				visualSettings,
				healthSystemId: userSession.healthSystem.id,
				backgroundImages: conferenceConfigs.backgroundImages,
				selectedCallBackground: selectedCallBackground || callBackground,
			});

			if (localParticipant.requestedLocalTracks.includes(Cam) && (selectedCallBackground || callBackground)) {
				changeLocalParticipantBackground(params);
			} else {
				await localParticipant.localTrackController.add(localParticipant.requestedLocalTracks);
			}
		} catch (error) {
			if (error?.error?.name === UserPermissionDeniedErrors.NotAllowedError) {
				setPermissionsDenied(true);
				conference.close();
				return;
			}
		}

		localParticipant.remoteTrackController.request(callTypeToTrackTypes(conference.callType).remoteTrackTypes);

		let isSuccessful;
		if (isJoin) {
			isSuccessful = await conference.join();
		} else {
			isSuccessful = await conference.start(participantsToCall);
		}

		if (isSuccessful) {
			conference.logger.debug(`Conference ${isJoin ? 'join()' : 'start()'} successful.`);
		}
	};

	const endCall = async evt => {
		evt.stopPropagation();
		conference.leave();
		conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
	};

	const transferrableParticipants = conferenceParticipants.filter(
		participant =>
			!(participant instanceof RemoteHelloParticipant) &&
			[UserTypes.NURSE, UserTypes.DOCTOR].includes(participant.role) &&
			participant.objectType !== ObjectType.SIP_USER
	);

	useEffect(() => {
		startConference();

		return () => {
			stopOutgoingCallSound();
		};
		// Disable reason: useEffect should be invoked once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const onBeforeUnload = event => {
			if (!(conference.state instanceof Conference.StateEnded)) {
				event.preventDefault();
				// eslint-disable-next-line no-param-reassign
				event.returnValue = '';
			}
		};

		const onUnload = () => {
			if (!(conference.state instanceof Conference.StateEnded)) {
				conference.leave();
			}
		};

		window.addEventListener('beforeunload', onBeforeUnload);
		window.addEventListener('unload', onUnload);

		const unbindConferenceEvents = conference.on(event => {
			if (event instanceof Conference.StateEnded) {
				afterConferenceEndedTimeoutRef.current = () => {
					setTimeout(props.onCallEnded, 3000);
				};
				afterConferenceEndedTimeoutRef.current();
			}
		});

		return () => {
			unbindConferenceEvents();

			if (afterConferenceEndedTimeoutRef.current) {
				clearTimeout(afterConferenceEndedTimeoutRef.current);
			}

			window.removeEventListener('beforeunload', onBeforeUnload);
			window.removeEventListener('unload', onUnload);
		};
	}, [conference, props.onCallEnded]);

	useEffect(() => {
		const fetchImages = async () => {
			const images = await fetchVirtualBackgroundImages(
				visualSettings,
				userSession,
				conferenceConfigs.setConferenceErrorMessages
			);
			if (images.length > 0) {
				conferenceConfigs.setBackgroundImages(images);
			}
		};
		fetchImages();
	}, []);

	if (isInitiating) {
		return <></>;
	}

	return (
		<>
			<StyledObserverConference $isMinimizedView={conferenceConfigs.isMinimizedView} $isDarkMode={isDarkMode}>
				{conferenceState instanceof Conference.StateEnded && (
					<p>
						<ConferenceEnded participantsToCall={participantsToCall} permissionsDenied={permissionsDenied} />
					</p>
				)}
				{!callStarted && !(conferenceState instanceof Conference.StateEnded) && (
					<footer>
						<p>
							<InitiatingConference participantsToCall={participantsToCall} />
						</p>
						<Tooltip text={intl.formatMessage({ id: 'endCall' })}>
							<IconButton
								background={LightTheme.colors.redOne}
								color={LightTheme.colors.grayZero}
								onClick={evt => {
									evt.stopPropagation();
									conference.leave();
								}}>
								<CallEndIcon height={18} width={18} />
							</IconButton>
						</Tooltip>
					</footer>
				)}
				{callStarted && !(conferenceState instanceof Conference.StateEnded) && (
					<>
						{conferenceConfigs.isMinimizedView && (
							<div className='flex column-direction text-align-center bottom-15'>
								{conference?.conferenceName && <p>{conference.conferenceName}</p>}
								<Duration startTime={conference.startTime} />
							</div>
						)}
						<StyledLayoutCallModal
							$isDarkMode={conferenceConfigs.isDarkMode}
							$isViewPatient={conference.callType === enums.CallTypes.SECURITYCAM}
							$gridCount={conferenceConfigs.gridCount}
							$isMinimizedView={conferenceConfigs.isMinimizedView}
							$isCallSettingsPanelOpen={
								conferenceConfigs.isInviteParticipantsModalViewOpen ||
								conferenceConfigs.isStreamSettingsModalOpen ||
								conferenceConfigs.isVirtualBackgroundModalOpen
							}
							$isMoreParticipantsViewOpen={conferenceConfigs.isMoreParticipantsViewOpen}>
							<div className='participants-wrapper'>
								{conference.callType !== enums.CallTypes.SECURITYCAM && (
									<>
										<ParticipantsView
											mainParticipant={mainParticipant}
											activeTrackType={activeTrackType}
											setMainParticipant={participant => setMainParticipant(participant)}
											setActiveTrackType={setActiveTrackType}
										/>
										{conferenceConfigs.isInviteParticipantsModalViewOpen && (
											<InviteParticipantsView
												position={!conferenceConfigs.isMinimizedView && 'right'}
												roomId={roomId}
												configurations={{
													isMeetingURLOn: true,
													isInviteViaSmsOn: true,
													isDialInOn: true,
													isInviteViaEmailOn: true,
													isTranslationServicesOn: true,
												}}
												onDismiss={() =>
													conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL)
												}
											/>
										)}
									</>
								)}
								{!conferenceConfigs.isGridView && (
									<MainParticipantView
										ref={mainParticipantVideoRef}
										participant={mainParticipant}
										activeTrackType={activeTrackType}
									/>
								)}
							</div>
							{conferenceConfigs.isMinimizedView && (
								<StyledMinimizedCallControl>
									<div>
										{conference.callType !== enums.CallTypes.SECURITYCAM && (
											<Tooltip
												text={
													localTracks[Cam]
														? intl.formatMessage({ id: 'turnCameraOff' })
														: intl.formatMessage({ id: 'turnCameraOn' })
												}>
												<IconButton
													background={getCallsControlsButtonBackground(isDarkMode, true)}
													color={getCallsButtonColor(isDarkMode)}
													onClick={async evt => {
														evt.preventDefault();
														evt.stopPropagation();
														try {
															const callBackground = userSettings[UserSettings.CALL_BACKGROUND];
															let selectedCallBackground = '';
															if (!localParticipant.localTrackController.tracks[Cam]) {
																selectedCallBackground = await getSelectedBackgroundImage(userSettings, visualSettings);
															}

															const params = await getUserBackgroundParams({
																localParticipant,
																userSettings,
																visualSettings,
																healthSystemId: userSession.healthSystem.id,
																backgroundImages: conferenceConfigs.backgroundImages,
																selectedCallBackground: selectedCallBackground || callBackground,
															});

															if (
																(selectedCallBackground || callBackground) &&
																!localParticipant.localTrackController.tracks[Cam]
															) {
																changeLocalParticipantBackground(params);
															} else {
																await localParticipant.localTrackController.toggle(Cam);
															}
														} catch (error) {
															// handleLocalCameraErrors(error);
														}
													}}>
													{localTracks[Cam] && <VideocamIcon height={18} width={18} color={getCallsButtonColor(isDarkMode)} />}
													{!localTracks[Cam] && (
														<VideocamOffIcon height={18} width={18} color={getCallsButtonColor(isDarkMode)} />
													)}
												</IconButton>
											</Tooltip>
										)}
										<Tooltip
											text={
												localTracks[Mic] ? intl.formatMessage({ id: 'turnOffMic' }) : intl.formatMessage({ id: 'turnOnMic' })
											}>
											<IconButton
												background={getCallsControlsButtonBackground(isDarkMode, true)}
												color={getCallsButtonColor(isDarkMode)}
												onClick={async evt => {
													evt.preventDefault();
													evt.stopPropagation();
													try {
														await localParticipant.localTrackController.toggle(Mic);
													} catch (error) {
														// handleLocalParticipantMicErrors(error);
													}
												}}>
												{localTracks[Mic] && <MicIcon height={18} width={18} color={getCallsButtonColor(isDarkMode)} />}
												{!localTracks[Mic] && <MicOffIcon height={18} width={18} color={getCallsButtonColor(isDarkMode)} />}
											</IconButton>
										</Tooltip>
										<Tooltip text={intl.formatMessage({ id: 'endCall' })}>
											<IconButton background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} onClick={endCall}>
												<CallEndIcon height={18} width={18} />
											</IconButton>
										</Tooltip>
										{conference.callType !== enums.CallTypes.FIRST_RESPONDER &&
											conference.callType !== enums.CallTypes.SECURITYCAM && (
												<Tooltip text={intl.formatMessage({ id: 'invitePeople' })}>
													<IconButton
														background={getCallsControlsButtonBackground(isDarkMode, true)}
														color={getCallsButtonColor(isDarkMode)}
														onClick={evt => {
															evt.stopPropagation();
															conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL);
														}}>
														<InviteUserIcon height={18} width={18} color={getCallsButtonColor(isDarkMode)} />
													</IconButton>
												</Tooltip>
											)}
										<Tooltip
											text={intl.formatMessage({ id: conferenceConfigs.isMinimizedView ? 'maximizeFeed' : 'minimizeFeed' })}>
											<IconButton
												background={getCallsControlsButtonBackground(isDarkMode, true)}
												color={getCallsButtonColor(isDarkMode)}
												onClick={evt => {
													evt.stopPropagation();
													conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_MINIMIZED_VIEW);
													conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW, false);
												}}>
												{conferenceConfigs.isMinimizedView && (
													<MaximizeIcon height={18} width={18} color={getCallsButtonColor(isDarkMode)} />
												)}
												{!conferenceConfigs.isMinimizedView && (
													<MinimizeIcon height={18} width={18} color={getCallsButtonColor(isDarkMode)} />
												)}
											</IconButton>
										</Tooltip>
									</div>
								</StyledMinimizedCallControl>
							)}
							{!conferenceConfigs.isMinimizedView && (
								<CallControlsView
									transferrableParticipants={transferrableParticipants}
									mainParticipant={mainParticipant}
									setCanScreenShare={setCanScreenShare}
								/>
							)}
						</StyledLayoutCallModal>
					</>
				)}
			</StyledObserverConference>
			{conferenceConfigs.removeParticipantModal.isOpen && (
				<RemoveParticipantModal
					display={true}
					onModalClose={() =>
						conferenceConfigs.setRemoveParticipantModal({ isOpen: false, modalMessage: '', onSubmit: () => {} })
					}
					primaryButtonLabel={intl.formatMessage({ id: 'removeParticipant' })}
					onModalSubmit={() => conferenceConfigs.removeParticipantModal.onSubmit()}
					position='center'>
					<form>
						<h4>{intl.formatMessage({ id: 'confirmParticipantRemoval' })}</h4>
						<p>{conferenceConfigs.removeParticipantModal.modalMessage}</p>
					</form>
				</RemoveParticipantModal>
			)}
			{!conferenceConfigs.isMinimizedView && canScreenShare && !canScreenShare[localParticipant.id] && (
				<Modal
					onDismiss={() => setCanScreenShare({ ...canScreenShare, [localParticipant.id]: true })}
					title={intl.formatMessage({ id: 'screenShareNotAllowed' })}>
					<Modal.Content>
						<p>{translate('screenSharingNotAllowed')}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button type='submit' onClick={() => setCanScreenShare({ ...canScreenShare, [localParticipant.id]: true })}>
							Ok
						</Button>
					</Modal.Actions>
				</Modal>
			)}
			{!conferenceConfigs.isMinimizedView && conferenceConfigs.isStreamSettingsModalOpen && (
				<StreamSettingsView
					position='right'
					localParticipant={localParticipant}
					onDismiss={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL)}
				/>
			)}
			{!conferenceConfigs.isMinimizedView && conferenceConfigs.isVirtualBackgroundModalOpen && (
				<VirtualBackgroundProvider>
					<VirtualBackgroundView
						position='right'
						onDismiss={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL)}
					/>
				</VirtualBackgroundProvider>
			)}
		</>
	);
};

const ConferenceModal = props => {
	const { incomingConferenceInfo, startConferenceData, isDarkMode, ...rest } = props;

	const callType = incomingConferenceInfo?.callType || startConferenceData?.callType;

	const conferenceInfo = {
		callType,
		...(incomingConferenceInfo ? { conferenceId: incomingConferenceInfo.conferenceId } : null),
		...(incomingConferenceInfo ? { participantId: incomingConferenceInfo.participantId } : null),
		conferenceName: incomingConferenceInfo?.conferenceName || startConferenceData?.conferenceName,
	};

	if (!conferenceInfo.conferenceId && !startConferenceData?.participants?.length) {
		// eslint-disable-next-line no-console
		console.warn(`Missing participants to call!`);
		props.onCallEnded();
		return <></>;
	}

	return (
		<ConferenceProvider conferenceInfo={conferenceInfo}>
			<ConferenceModalComponent
				{...rest}
				participantsToCall={startConferenceData?.participants}
				isJoin={!!conferenceInfo.conferenceId}
				roomId={startConferenceData.roomId}
				isDarkMode={isDarkMode}
			/>
		</ConferenceProvider>
	);
};

export default ConferenceModal;
