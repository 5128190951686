import {
	addPatientInfoObservations,
	addPrimaryPatientConditions,
	removePatientInfoObservations,
	removePrimaryPatientConditions,
	setGenderIdentity,
	setPatientAge,
	setPatientCareTeamParticipants,
	setPatientName,
	setPatientPreferredLanguage,
} from 'api/patients.js';
import { getAllVerbalRedirectionLanguages, getHealthSystemVerbalRedirectionLanguages } from 'api/verbalRedirections.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import classNames from 'classnames';
import Button from 'components/Button.jsx';
import FormInput from 'components/FormInput.jsx';
import { Alert, CustomDropdown, Dropdown, Form, Grid, Loader } from 'components/index.js';
import { MonitoringSettings, PatientInfoFieldsVariants } from 'constants/configurationEnums.js';
import { CareTeamEhrRoles, ConditionType, ObservationType } from 'constants/enums.js';
import {
	CareTeamParticipantRole,
	genderIdentityArr,
	primaryAdverseEvent,
	primaryPatientCondition,
	primaryPatientRiskFactor,
} from 'constants/monitoring.js';
import translate from 'i18n-translations/translate.jsx';
import CalendarIcon from 'icons/Dashboard/CalendarIcon.jsx';
import { DischargeIcon, EditIcon, ProfileIcon } from 'icons/Monitoring/index.js';
import {
	getAge,
	getConfigurationValue,
	getConfigurationVariant,
	setSimplifiedLanguageLabels,
} from 'infrastructure/helpers/commonHelpers.js';
import { customFormattedDate } from 'infrastructure/helpers/dateHelper.js';
import { mapSelectedOptions } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import ClearData from 'icons/Monitoring/ClearData.jsx';
import { startDeletePatientInfoTask } from 'api/monitoring.js';

const PatientNotes = ({
	deviceId,
	roomId,
	hierarchyNaming,
	isDarkMode,
	mrn,
	handleDiscontinue,
	healthSystemId,
	deviceOwnerId,
	conferenceId,
	shouldShowDiscontinueReasons,
	isVirtualPatient,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const maxCharacterLength = 255;
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [preferredLanguages, setPreferredLanguages] = useState([]);
	const patientDataSaved = useSelector(state => state.patientNotes.savedMode);
	const healthSystemConfigurations = useSelector(state => state.configurations.healthSystemConfigurations);
	const [patientNotes, setPatientNotes] = useState({
		patientName: '',
		patientAge: null,
		birthDate: '',
		nurseNameNumber: '',
		preferredLanguage: null,
		nonFoundPreferredLanguage: '',
		isInterpreterRequired: false,
		genderIdentity: null,
		nurseAssistantNameNumber: '',
		tlNameNumber: '',
		ehrCareTeam: null,
		indicationForVideoMonitoring: null,
		primaryAdverseEvent: [],
		primaryPatientCondition: [],
		primaryPatientRiskFactor: [],
		titleBarNotes: '',
		additionalNotes: '',
		encounterId: '',
	});
	const [expandedBox, setExpandedBox] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);

	const initialPatientNotes = useRef(null);
	const prevDeviceIdRef = useRef();
	const prevRoomIdRef = useRef();

	const patientNotesProperties = [
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientName]) && 'patientName',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientAge]) && 'birthDate',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientAge]) && 'patientAge',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientGenderIdentity]) && 'genderIdentity',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseNameNumber]) && 'nurseNameNumber',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseAssistantNameNumber]) && 'nurseAssistantNameNumber',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.TlNameNumber]) && 'tlNameNumber',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.IndicationForVideoMonitoring]) &&
			'indicationForVideoMonitoring',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryAdverseEvent]) && 'primaryAdverseEvent',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryPatientCondition]) && 'primaryPatientCondition',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryPatientRiskFactor]) && 'primaryPatientRiskFactor',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PreferredLanguage]) && 'preferredLanguage',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.InterpreterNeeded]) && 'isInterpreterRequired',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.TitleBarNotes]) && 'titleBarNotes',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.AdditionalPatientNotes]) && 'additionalNotes',
	].filter(Boolean);

	const isEhrField = key =>
		getConfigurationValue(healthSystemConfigurations[key]) &&
		getConfigurationVariant(healthSystemConfigurations[key]) === PatientInfoFieldsVariants.EHR_PATIENT_DATA_LOAD;

	useEffect(() => {
		const fetchSelectedLanguages = async () => {
			if (!healthSystemId) {
				return;
			}
			const response = isEhrField(MonitoringSettings.PreferredLanguage)
				? await getAllVerbalRedirectionLanguages()
				: await getHealthSystemVerbalRedirectionLanguages(healthSystemId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = isEhrField(MonitoringSettings.PreferredLanguage)
				? response.languages.map(item => ({
						...item,
						value: item.id,
						label: item.localeName,
				  }))
				: setSimplifiedLanguageLabels(response.languages);
			setPreferredLanguages(mapped);
		};
		fetchSelectedLanguages();
	}, [intl, healthSystemId]);

	useEffect(() => {
		if (roomId !== prevRoomIdRef.current && showEditForm) {
			handleSubmit(true);
		}
		if (!patientDataSaved?.[deviceId]?.hasFetchedNotes) {
			return;
		}
		setPatientNotes(prevState => ({
			...prevState,
			...patientDataSaved?.[deviceId]?.patientInfoData,
			preferredLanguage: getPreferredLanguage(patientDataSaved?.[deviceId]?.patientInfoData?.preferredLanguage),
		}));
		prevDeviceIdRef.current = deviceId;
		prevRoomIdRef.current = roomId;
	}, [deviceId, roomId, preferredLanguages, patientDataSaved?.[deviceId]?.hasFetchedNotes]);

	useEffect(() => {
		if (patientDataSaved?.[deviceId]?.hasFetchedNotes) {
			setPatientNotes(prevState => ({
				...prevState,
				...patientDataSaved?.[deviceId]?.patientInfoData,
				preferredLanguage: getPreferredLanguage(patientDataSaved?.[deviceId]?.patientInfoData?.preferredLanguage),
			}));
		}
	}, [deviceId, patientDataSaved?.[deviceId]?.patientInfoData]);

	const getUpdatedFields = () => {
		const updatedFields = {};
		Object.keys(initialPatientNotes.current).forEach(key => {
			if (['string', 'number'].includes(typeof patientNotes[key]) && patientNotes[key] !== initialPatientNotes.current[key]) {
				updatedFields[key] = patientNotes[key] || '';
			} else if (Array.isArray(patientNotes[key])) {
				const removed = initialPatientNotes.current[key].filter(item => !patientNotes[key].includes(item));
				const added = patientNotes[key].filter(item => !initialPatientNotes.current[key].includes(item));
				updatedFields[key] = { removed, added };
			} else if (['preferredLanguage'].includes(key) && initialPatientNotes.current[key]?.id !== patientNotes[key]?.id) {
				updatedFields[key] = patientNotes[key];
			}
		});
		return updatedFields;
	};

	const handleUpdatedObservations = updatedFields => {
		const observationsToAdd = [];
		const observationsToRemove = [];
		const observationTypes = {
			titleBarNotes: ObservationType.ADDITIONAL_TITLE_BAR_NOTES,
			additionalNotes: ObservationType.ADDITIONAL_PATIENT_NOTES,
			primaryAdverseEvent: ObservationType.PRIMARY_ADVERSE_EVENT,
			primaryPatientRiskFactor: ObservationType.PRIMARY_PATIENT_RISK_FACTOR,
		};

		Object.keys(observationTypes).forEach(field => {
			if (!(field in updatedFields)) {
				return;
			}
			if (typeof updatedFields[field] === 'object') {
				observationsToRemove.push(
					...updatedFields[field].removed.map(item => ({
						...item,
						observationTypeId: observationTypes[field],
					}))
				);
				observationsToAdd.push(
					...updatedFields[field].added.map(item => ({
						...item,
						observationTypeId: observationTypes[field],
					}))
				);
			} else {
				(updatedFields[field] ? observationsToAdd : observationsToRemove).push({
					code: field,
					display: updatedFields[field] || null,
					observationTypeId: observationTypes[field],
				});
			}
		});

		return { observationsToAdd, observationsToRemove };
	};

	const handleSubmit = async savePrevious => {
		const ownerId = deviceOwnerId;
		const updatedFields = getUpdatedFields();
		const deviceIdToSave = savePrevious ? prevDeviceIdRef.current : deviceId;
		setIsLoading(true);

		if ('patientName' in updatedFields) {
			const firstName = updatedFields.patientName?.split(' ')?.[0] || null;
			const lastName = updatedFields.patientName?.split(' ')?.slice(1).join(' ') || null;
			await setPatientName({ healthSystemId, conferenceId, ownerId, firstName, lastName });
		}
		if ('birthDate' in updatedFields) {
			await setPatientAge({ healthSystemId, conferenceId, ownerId, dateOfBirth: updatedFields.birthDate || null });
		}
		if ('genderIdentity' in updatedFields) {
			await setGenderIdentity({ healthSystemId, conferenceId, ownerId, genderIdentity: updatedFields.genderIdentity || null });
		}
		if (updatedFields?.preferredLanguage) {
			const language = updatedFields.preferredLanguage.locale.split('-')[0];
			await setPatientPreferredLanguage({ conferenceId, ownerId, language });
		}
		const careTeamFields = ['nurseNameNumber', 'nurseAssistantNameNumber', 'tlNameNumber'];
		const careTeamRoles = {
			nurseNameNumber: CareTeamParticipantRole.REGISTERED_NURSE,
			nurseAssistantNameNumber: CareTeamParticipantRole.PATIENT_CARE_TECHNICIAN,
			tlNameNumber: CareTeamParticipantRole.NURSING_TEAM_LEADER,
		};
		if (careTeamFields.some(field => field in updatedFields)) {
			const participants = [];
			careTeamFields.forEach(field => {
				if (patientNotes[field]) {
					participants.push({ name: patientNotes[field], participantRole: careTeamRoles[field] });
				}
			});
			await setPatientCareTeamParticipants({ healthSystemId, conferenceId, ownerId, participants });
		}
		const { observationsToAdd, observationsToRemove } = handleUpdatedObservations(updatedFields);
		if (observationsToRemove.length > 0) {
			const observations = observationsToRemove.map(({ code, display, observationTypeId }) => ({
				code,
				display,
				observationTypeId,
			}));
			await removePatientInfoObservations({
				healthSystemId,
				conferenceId,
				ownerId,
				deviceId: deviceIdToSave,
				observations,
			});
		}
		if (observationsToAdd.length > 0) {
			const observations = observationsToAdd.map(({ code, display, observationTypeId }) => ({
				code,
				display,
				observationTypeId,
			}));
			await addPatientInfoObservations({
				healthSystemId,
				conferenceId,
				ownerId,
				observations,
			});
		}
		if (updatedFields.primaryPatientCondition?.removed.length > 0) {
			const conditions = updatedFields.primaryPatientCondition.removed.map(({ code, display }) => ({
				code,
				display,
				conditionType: ConditionType.PRIMARY_PATIENT_CONDITION,
			}));
			await removePrimaryPatientConditions({ conferenceId, ownerId, conditions });
		}
		if (updatedFields.primaryPatientCondition?.added.length > 0) {
			const conditions = updatedFields.primaryPatientCondition.added.map(({ code, display }) => ({
				code,
				display,
				conditionType: ConditionType.PRIMARY_PATIENT_CONDITION,
			}));
			await addPrimaryPatientConditions({ conferenceId, ownerId, conditions });
		}
		initialPatientNotes.current = { ...patientNotes };
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{
					...patientDataSaved[deviceIdToSave],
					patientInfoData: {
						...patientDataSaved[deviceIdToSave]?.patientInfoData,
						...patientNotes,
					},
					notes: patientDataSaved[deviceIdToSave]?.notes || '',
				},
				deviceIdToSave
			)
		);
		setIsLoading(false);
		setShowEditForm(false);
	};

	const hasPatientInfo = () => {
		let result = false;
		for (let i = 0; i < patientNotesProperties.length; i++) {
			const item = patientNotes[patientNotesProperties?.[i]];
			if (item && Array.isArray(item) && item.length > 0) {
				result = true;
				break;
			}
			if (item && !Array.isArray(item)) {
				result = true;
				const arr = ['nurseNameNumber', 'nurseAssistantNameNumber', 'indicationForVideoMonitoring'];
				if (arr.includes(patientNotesProperties?.[i])) {
					if (patientNotesProperties?.[i] !== 'indicationForVideoMonitoring') {
						result = false;
					} else {
						result = item?.arr?.length > 0;
					}
				}
				break;
			}
			if (patientNotesProperties?.[i] === 'preferredLanguage') {
				result = false;
				if (item || (isEhrField(MonitoringSettings.PreferredLanguage) && !item && patientNotes?.nonFoundPreferredLanguage)) {
					result = true;
				}
				break;
			}
			if (
				mrn ||
				(isEhrField(MonitoringSettings.NurseNameNumber) && patientNotes?.ehrCareTeam?.nurse) ||
				(isEhrField(MonitoringSettings.NurseAssistantNameNumber) && patientNotes?.ehrCareTeam?.pct) ||
				(isEhrField(MonitoringSettings.TlNameNumber) && patientNotes?.ehrCareTeam?.tl) ||
				(!isEhrField(MonitoringSettings.NurseNameNumber) && patientNotesProperties?.[i] === 'nurseNameNumber' && item) ||
				(!isEhrField(MonitoringSettings.NurseAssistantNameNumber) &&
					patientNotesProperties?.[i] === 'nurseAssistantNameNumber' &&
					item) ||
				(!isEhrField(MonitoringSettings.TlNameNumber) && patientNotesProperties?.[i] === 'tlNameNumber' && item)
			) {
				result = true;
				break;
			}
		}
		return result;
	};

	const handleClear = async event => {
		event.preventDefault();
		setIsLoading(true);
		const response = await startDeletePatientInfoTask(healthSystemId, deviceOwnerId, conferenceId);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			setIsLoading(false);
			return;
		}
		const emptyNotes = {
			patientName: '',
			patientAge: null,
			birthDate: '',
			nurseNameNumber: '',
			preferredLanguage: null,
			nonFoundPreferredLanguage: '',
			genderIdentity: null,
			nurseAssistantNameNumber: '',
			tlNameNumber: '',
			primaryAdverseEvent: [],
			primaryPatientCondition: [],
			primaryPatientRiskFactor: [],
			titleBarNotes: '',
			additionalNotes: '',
		};
		setPatientNotes(prevState => ({
			...prevState,
			...emptyNotes,
		}));
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{
					...patientDataSaved[deviceId],
					patientInfoData: emptyNotes,
					notes: '',
				},
				deviceId
			)
		);
		setIsLoading(false);
		setShowEditForm(false);
	};

	const getTableData = () => {
		const tableRows = [];
		const languageItem = 'preferredLanguage';

		tableRows.push(
			<>
				{mrn && (
					<tr key='patientMrn'>
						<td>MRN</td>
						<td>{mrn}</td>
					</tr>
				)}
			</>
		);

		for (let i = 0; i < patientNotesProperties.length; i++) {
			const patientNote = patientNotesProperties[i];
			const noteItem = patientNotes[patientNote];
			if (noteItem && Array.isArray(noteItem) && noteItem.length > 0) {
				const row = (
					<tr key={patientNote}>
						<td>{intl.formatMessage({ id: patientNote })}</td>
						<td>{mapSelectedOptions(noteItem)}</td>
					</tr>
				);
				tableRows.push(row);
			}

			if (!Array.isArray(noteItem) && patientNote !== 'indicationForVideoMonitoring' && noteItem?.toString()) {
				let row;

				const arr = [
					'preferredLanguage',
					'genderIdentity',
					'nurseNameNumber',
					'nurseAssistantNameNumber',
					'tlNameNumber',
					'nonFoundPreferredLanguage',
				];
				if (!arr.includes(patientNote)) {
					row = (
						<tr key={patientNote}>
							<td>{patientNote === 'birthDate' ? 'DOB' : intl.formatMessage({ id: patientNote })}</td>
							<td {...(['titleBarNotes', 'additionalNotes'].includes(patientNote) && { className: 'break-word' })}>
								{patientNote === 'birthDate' ? customFormattedDate(noteItem, 'MM/DD/YYYY') : noteItem}
							</td>
						</tr>
					);
				}

				if (patientNote === 'genderIdentity') {
					row = (
						<tr key={patientNote}>
							<td>{intl.formatMessage({ id: patientNote })}</td>
							<td>{genderIdentityArr(intl).find(item => item.value === noteItem)?.label || ''}</td>
						</tr>
					);
				}
				if (patientNote === 'isInterpreterRequired') {
					row = (
						<tr key={patientNote}>
							<td>{intl.formatMessage({ id: 'interpreterNeededText' })}</td>
							<td>{translate(noteItem ? 'yes' : 'no')}</td>
						</tr>
					);
				}

				tableRows.push(row);
			}
			if (patientNote === 'indicationForVideoMonitoring') {
				const row = (
					<tr key={patientNote}>
						<td>{intl.formatMessage({ id: patientNote })}</td>
						<td>
							{patientNotes?.indicationForVideoMonitoring?.arr?.map(item => item).join(', ')}
							{patientNotes?.indicationForVideoMonitoring?.note && (
								<>
									<br />
									<br />
									{`${intl.formatMessage({ id: 'comment' })}: ${patientNotes?.indicationForVideoMonitoring?.note}`}
								</>
							)}
						</td>
					</tr>
				);
				if (patientNotes?.indicationForVideoMonitoring?.arr?.length > 0 || patientNotes?.indicationForVideoMonitoring?.note) {
					tableRows.push(row);
				}
			}
			if (!Array.isArray(noteItem)) {
				let row;
				if (patientNote === languageItem && noteItem?.toString()) {
					row = (
						<tr key={patientNote}>
							<td>{intl.formatMessage({ id: patientNote })}</td>
							<td>
								{isEhrField(MonitoringSettings.PreferredLanguage) ? patientNotes?.nonFoundPreferredLanguage : noteItem.label}
							</td>
						</tr>
					);
				}
				if (
					isEhrField(MonitoringSettings.PreferredLanguage) &&
					patientNote === languageItem &&
					!noteItem?.toString() &&
					patientNotes?.nonFoundPreferredLanguage
				) {
					row = (
						<tr key={patientNote}>
							<td>{intl.formatMessage({ id: patientNote })}</td>
							<td>{patientNotes?.nonFoundPreferredLanguage}</td>
						</tr>
					);
				}
				if (patientNote === 'nurseNameNumber') {
					row = (
						<>
							{isEhrField(MonitoringSettings.NurseNameNumber) && patientNotes?.ehrCareTeam?.rn && (
								<tr key='nurseNameNumber'>
									<td>{intl.formatMessage({ id: 'nurseNameNumber' })}</td>
									<td>
										{patientNotes?.ehrCareTeam?.rn?.name || ''}
										<br />
										{patientNotes?.ehrCareTeam?.rn?.contactPhoneNumber || ''}
									</td>
								</tr>
							)}
							{!isEhrField(MonitoringSettings.NurseNameNumber) && patientNotes?.nurseNameNumber && (
								<tr key='nurseNameNumber'>
									<td>{intl.formatMessage({ id: 'nurseNameNumber' })}</td>
									<td>{patientNotes?.nurseNameNumber}</td>
								</tr>
							)}
						</>
					);
				}
				if (patientNote === 'nurseAssistantNameNumber') {
					row = (
						<>
							{isEhrField(MonitoringSettings.NurseAssistantNameNumber) && patientNotes?.ehrCareTeam?.pct && (
								<tr key='nurseAssistantNameNumber'>
									<td>{intl.formatMessage({ id: 'nurseAssistantNameNumber' })}</td>
									<td>
										{patientNotes?.ehrCareTeam?.pct?.name || ''}
										<br />
										{patientNotes?.ehrCareTeam?.pct?.contactPhoneNumber || ''}
									</td>
								</tr>
							)}
							{!isEhrField(MonitoringSettings.NurseAssistantNameNumber) && patientNotes?.nurseAssistantNameNumber && (
								<tr key='nurseNameNumber'>
									<td>{intl.formatMessage({ id: 'nurseAssistantNameNumber' })}</td>
									<td>{patientNotes?.nurseAssistantNameNumber}</td>
								</tr>
							)}
						</>
					);
				}
				if (patientNote === 'tlNameNumber') {
					row = (
						<>
							{isEhrField(MonitoringSettings.TlNameNumber) && patientNotes?.ehrCareTeam?.tl && (
								<tr key='tlNameNumber'>
									<td>{intl.formatMessage({ id: 'tlNameNumber' })}</td>
									<td>
										{patientNotes?.ehrCareTeam?.tl?.name || ''}
										<br />
										{patientNotes?.ehrCareTeam?.tl?.contactPhoneNumber || ''}
									</td>
								</tr>
							)}
							{!isEhrField(MonitoringSettings.TlNameNumber) && patientNotes?.tlNameNumber && (
								<tr key='nurseNameNumber'>
									<td>{intl.formatMessage({ id: 'tlNameNumber' })}</td>
									<td>{patientNotes?.tlNameNumber}</td>
								</tr>
							)}
						</>
					);
				}
				tableRows.push(row);
			}
		}
		return tableRows;
	};

	const PatientInfo = () => {
		if (!hasPatientInfo()) {
			return (
				<table className='patient-table-informations' style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td>{translate('roomWithoutPatientData')}</td>
						</tr>
					</tbody>
				</table>
			);
		}

		return (
			<>
				<p>{`${hierarchyNaming.hospital}, ${hierarchyNaming.floor}, ${hierarchyNaming.room}`}</p>
				<table className='patient-table-informations' style={{ width: '100%' }}>
					<tbody>{getTableData()}</tbody>
				</table>
			</>
		);
	};

	const getInputValue = (isEhr, nameKey, notesData) => {
		if (isEhr && patientNotes?.ehrCareTeam) {
			const name = patientNotes?.ehrCareTeam?.[nameKey]?.name || '';
			const number = patientNotes?.ehrCareTeam?.[nameKey]?.contactPhoneNumber || '';
			return `${name}${name && number && '/'}${number}`;
		}
		if (notesData) {
			return notesData;
		}
		return '';
	};

	const getPreferredLanguage = languageLocale => {
		if (!preferredLanguages.length || !languageLocale) {
			return null;
		}
		const locale = typeof languageLocale === 'object' ? languageLocale.locale : languageLocale;

		return preferredLanguages.find(
			language =>
				(isEhrField(MonitoringSettings.PreferredLanguage) && language.locale === locale) ||
				(!isEhrField(MonitoringSettings.PreferredLanguage) &&
					(language.locale === locale ||
						language.locale.split('-')?.[0] === locale?.split('-')?.[0] ||
						language.localeName.split(' ')?.[0] === locale?.split(' ')?.[0]))
		);
	};

	const handleDateChange = selectedDate => {
		setPatientNotes(prevState => ({
			...prevState,
			birthDate: selectedDate?.toISOString() || '',
			patientAge: selectedDate ? getAge(selectedDate) ?? '' : '',
		}));
	};

	const getGenderIdentityDefaultOption = () => {
		if (patientNotes?.genderIdentity) {
			return genderIdentityArr(intl).find(item => item.value === patientNotes?.genderIdentity);
		}
		return [];
	};

	return (
		<>
			<div className='monitoring-timeline-box'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title'>
						<ProfileIcon />
						{translate('patientInfo')}
					</p>
					<div className='timeline-box-actions'>
						{patientNotesProperties?.length > 0 && (
							<Button
								onClick={() => {
									setExpandedBox(true);
									setShowEditForm(prevState => !prevState);
									initialPatientNotes.current = { ...patientNotes };
								}}
								svgIcon={<EditIcon />}
								border='none'
							/>
						)}
						<Button
							border='none'
							onClick={() => setExpandedBox(prevState => !prevState)}
							icon={expandedBox ? 'expand_less' : 'expand_more'}
						/>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='patient-informations'>
							{isLoading && (
								<Grid columns='1fr' rows='1fr' horizAlign='center' vertAlign='center'>
									<Loader />
								</Grid>
							)}
							{!isLoading && (
								<>
									{!showEditForm && <PatientInfo />}
									{showEditForm && patientNotesProperties?.length > 0 && (
										<Form className='profile-information-form patient-notes-form'>
											<div className='flex flex-space-between flex-wrap'>
												<div className='room-level'>
													<p>{`${hierarchyNaming.hospital}, ${hierarchyNaming.floor}, ${hierarchyNaming.room}`}</p>

													<Dropdown position='bottom' icon='more_vert'>
														<Button
															className='button discharge-btn'
															onClick={() => handleDiscontinue(shouldShowDiscontinueReasons)}
															svgIcon={
																<DischargeIcon
																	height={16}
																	width={16}
																	color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive}
																/>
															}
															text={translate('discontinueMonitoring')}
														/>
														{isVirtualPatient && (
															<Button
																className='button discharge-btn full-width'
																onClick={handleClear}
																svgIcon={
																	<ClearData
																		height={16}
																		width={16}
																		color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive}
																	/>
																}
																text={translate('clearAllData')}
															/>
														)}
													</Dropdown>
												</div>
												{mrn && (
													<FormInput
														text='MRN'
														id='mrn'
														placeholder='MRN'
														type='text'
														value={mrn}
														onChange={() => null}
														className='text-input none-pointer-events disabled'
														maxLength={256}
														readOnly={true}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientName]) && (
													<FormInput
														text={translate('patientName')}
														id='patientName'
														placeholder={intl.formatMessage({
															id: isEhrField(MonitoringSettings.PatientName) ? 'noDataAvailable' : 'enterPatientName',
														})}
														type='text'
														value={patientNotes?.patientName}
														onChange={event => setPatientNotes(prevState => ({ ...prevState, patientName: event.target.value }))}
														className={classNames('text-input', {
															'none-pointer-events': isEhrField(MonitoringSettings.PatientName),
															disabled: isEhrField(MonitoringSettings.PatientName),
														})}
														maxLength={256}
														readOnly={isEhrField(MonitoringSettings.PatientName)}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientAge]) && (
													<div className='position-relative full-width'>
														<FormInput
															text={translate('patientAge')}
															id='patientAge'
															placeholder={intl.formatMessage({
																id: isEhrField(MonitoringSettings.PatientAge) ? 'noDataAvailable' : 'enterPatientAge',
															})}
															type='text'
															value={patientNotes?.patientAge}
															className={classNames('text-input none-pointer-events', {
																disabled: isEhrField(MonitoringSettings.PatientAge),
															})}
															maxLength={256}
															readOnly={true}
														/>
														{!isEhrField(MonitoringSettings.PatientAge) && (
															<div className='patient-notes-date-picker'>
																<DatePicker
																	showIcon={true}
																	icon={<CalendarIcon />}
																	selected={patientNotes?.birthDate}
																	onChange={handleDateChange}
																	dateFormat='MM/dd/yyyy'
																	maxDate={new Date()}
																	isClearable={patientNotes?.birthDate}
																	showMonthDropdown={true}
																	showYearDropdown={true}
																	dropdownMode='select'
																/>
															</div>
														)}
													</div>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientGenderIdentity]) && (
													<CustomDropdown
														labelName={intl.formatMessage({ id: 'genderIdentity' })}
														defaultOptions={getGenderIdentityDefaultOption()}
														initialOptions={genderIdentityArr(intl)}
														onSelect={selectedOptions => {
															setPatientNotes(prevState => ({
																...prevState,
																genderIdentity: +selectedOptions.value,
															}));
														}}
														className={classNames({
															'ehr-no-data-input':
																isEhrField(MonitoringSettings.PatientGenderIdentity) && !patientNotes?.genderIdentity,
															disabled: isEhrField(MonitoringSettings.PatientGenderIdentity),
														})}
														placeholder={
															isEhrField(MonitoringSettings.PatientGenderIdentity)
																? intl.formatMessage({
																		id: 'noDataAvailable',
																  })
																: `${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
																		id: 'genderIdentity',
																  })}`
														}
														isDisabled={isEhrField(MonitoringSettings.PatientGenderIdentity)}
														isMulti={false}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseNameNumber]) && (
													<FormInput
														text={translate('nurseNameNumber')}
														id='nurseNameNumber'
														placeholder={intl.formatMessage({
															id: isEhrField(MonitoringSettings.NurseNameNumber) ? 'noDataAvailable' : 'enterNurseNameNnumber',
														})}
														type='text'
														value={getInputValue(
															isEhrField(MonitoringSettings.NurseNameNumber),
															CareTeamEhrRoles.RN.toLowerCase(),
															patientNotes?.nurseNameNumber
														)}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, nurseNameNumber: event.target.value }))
														}
														className={classNames('text-input', {
															'none-pointer-events': isEhrField(MonitoringSettings.NurseNameNumber),
															disabled: isEhrField(MonitoringSettings.NurseNameNumber),
														})}
														maxLength={256}
														readOnly={isEhrField(MonitoringSettings.NurseNameNumber)}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseAssistantNameNumber]) && (
													<FormInput
														text={translate('nurseAssistantNameNumber')}
														id='nurseAssistantNameNumber'
														placeholder={intl.formatMessage({
															id: isEhrField(MonitoringSettings.NurseAssistantNameNumber)
																? 'noDataAvailable'
																: 'enterNurseAssistantNameNumber',
														})}
														type='text'
														value={getInputValue(
															isEhrField(MonitoringSettings.NurseAssistantNameNumber),
															CareTeamEhrRoles.PCT.toLowerCase(),
															patientNotes?.nurseAssistantNameNumber
														)}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, nurseAssistantNameNumber: event.target.value }))
														}
														className={classNames('text-input', {
															'none-pointer-events': isEhrField(MonitoringSettings.NurseAssistantNameNumber),
															disabled: isEhrField(MonitoringSettings.NurseAssistantNameNumber),
														})}
														maxLength={256}
														readOnly={isEhrField(MonitoringSettings.NurseAssistantNameNumber)}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.TlNameNumber]) && (
													<FormInput
														text={translate('tlNameNumber')}
														id='tlNameNumber'
														placeholder={intl.formatMessage({
															id: isEhrField(MonitoringSettings.TlNameNumber) ? 'noDataAvailable' : 'enterTlNameNumber',
														})}
														type='text'
														value={getInputValue(
															isEhrField(MonitoringSettings.TlNameNumber),
															CareTeamEhrRoles.TL.toLowerCase(),
															patientNotes?.tlNameNumber
														)}
														onChange={event => setPatientNotes(prevState => ({ ...prevState, tlNameNumber: event.target.value }))}
														className={classNames('text-input', {
															'none-pointer-events': isEhrField(MonitoringSettings.TlNameNumber),
															disabled: isEhrField(MonitoringSettings.TlNameNumber),
														})}
														maxLength={256}
														isDisabled={isEhrField(MonitoringSettings.TlNameNumber)}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.IndicationForVideoMonitoring]) && (
													<>
														{patientNotes?.indicationForVideoMonitoring?.arr?.length > 0 && (
															<label>
																{translate('indicationForVideoMonitoring')}
																<div className='text-input text-input-indication disabled'>
																	<p>
																		{patientNotes?.indicationForVideoMonitoring?.arr?.map(item => item).join(', ')}
																		{patientNotes?.indicationForVideoMonitoring?.note && (
																			<>
																				<br />
																				<br />
																				{`${intl.formatMessage({ id: 'comment' })}: ${
																					patientNotes?.indicationForVideoMonitoring?.note
																				}`}
																			</>
																		)}
																	</p>
																</div>
															</label>
														)}
														{(!patientNotes?.indicationForVideoMonitoring ||
															patientNotes?.indicationForVideoMonitoring?.arr?.length === 0) && (
															<FormInput
																text={translate('indicationForVideoMonitoring')}
																id='indicationForVideoMonitoring'
																placeholder={intl.formatMessage({
																	id: 'noDataAvailable',
																})}
																type='text'
																value=''
																className='text-input none-pointer-events disabled'
																maxLength={256}
																readOnly={true}
															/>
														)}
													</>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryAdverseEvent]) && (
													<>
														{!isEhrField(MonitoringSettings.PrimaryAdverseEvent) && (
															<CustomDropdown
																labelName={intl.formatMessage({ id: 'primaryAdverseEvent' })}
																defaultOptions={patientNotes?.primaryAdverseEvent || []}
																initialOptions={primaryAdverseEvent(intl)}
																onSelect={selectedOptions =>
																	setPatientNotes(prevState => ({
																		...prevState,
																		primaryAdverseEvent: selectedOptions,
																	}))
																}
																placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
																	id: 'primaryAdverseEvent',
																})}`}
																isDisabled={isEhrField(MonitoringSettings.PrimaryAdverseEvent)}
															/>
														)}
														{isEhrField(MonitoringSettings.PrimaryAdverseEvent) && (
															<FormInput
																text={translate('primaryAdverseEvent')}
																id='primaryAdverseEvent'
																placeholder={intl.formatMessage({ id: 'noDataAvailable' })}
																type='text'
																value=''
																onChange={event =>
																	setPatientNotes(prevState => ({ ...prevState, primaryAdverseEvent: event.target.value }))
																}
																className='text-input none-pointer-events disabled'
																maxLength={maxCharacterLength}
																readOnly={true}
															/>
														)}
													</>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryPatientCondition]) && (
													<>
														{!isEhrField(MonitoringSettings.PrimaryPatientCondition) && (
															<CustomDropdown
																labelName={intl.formatMessage({ id: 'primaryPatientCondition' })}
																defaultOptions={patientNotes?.primaryPatientCondition || []}
																initialOptions={primaryPatientCondition(intl)}
																onSelect={selectedOptions =>
																	setPatientNotes(prevState => ({
																		...prevState,
																		primaryPatientCondition: selectedOptions,
																	}))
																}
																placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
																	id: 'primaryPatientCondition',
																})}`}
																isDisabled={isEhrField(MonitoringSettings.PrimaryPatientCondition)}
															/>
														)}
														{isEhrField(MonitoringSettings.PrimaryPatientCondition) && (
															<FormInput
																text={translate('primaryPatientCondition')}
																id='primaryPatientCondition'
																placeholder={intl.formatMessage({ id: 'noDataAvailable' })}
																type='text'
																value=''
																onChange={event =>
																	setPatientNotes(prevState => ({ ...prevState, primaryPatientCondition: event.target.value }))
																}
																className='text-input none-pointer-events disabled'
																maxLength={maxCharacterLength}
																readOnly={true}
															/>
														)}
													</>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryPatientRiskFactor]) && (
													<>
														{!isEhrField(MonitoringSettings.PrimaryPatientRiskFactor) && (
															<CustomDropdown
																labelName={intl.formatMessage({ id: 'primaryPatientRiskFactor' })}
																defaultOptions={patientNotes?.primaryPatientRiskFactor || []}
																initialOptions={primaryPatientRiskFactor(intl)}
																onSelect={selectedOptions =>
																	setPatientNotes(prevState => ({ ...prevState, primaryPatientRiskFactor: selectedOptions }))
																}
																placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
																	id: 'primaryPatientRiskFactor',
																})}`}
																isDisabled={isEhrField(MonitoringSettings.PrimaryPatientRiskFactor)}
															/>
														)}
														{isEhrField(MonitoringSettings.PrimaryPatientRiskFactor) && (
															<FormInput
																text={translate('primaryPatientRiskFactor')}
																id='primaryPatientRiskFactor'
																placeholder={intl.formatMessage({ id: 'noDataAvailable' })}
																type='text'
																value=''
																onChange={event =>
																	setPatientNotes(prevState => ({ ...prevState, primaryPatientRiskFactor: event.target.value }))
																}
																className='text-input none-pointer-events disabled'
																maxLength={maxCharacterLength}
																readOnly={true}
															/>
														)}
													</>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PreferredLanguage]) && (
													<CustomDropdown
														labelName={intl.formatMessage({ id: 'preferredLanguage' })}
														defaultOptions={
															patientNotes?.preferredLanguage || patientNotes?.nonFoundPreferredLanguage
																? {
																		...patientNotes?.preferredLanguage,
																		...(isEhrField(MonitoringSettings.PreferredLanguage) && {
																			label: patientNotes?.nonFoundPreferredLanguage,
																		}),
																  }
																: []
														}
														initialOptions={preferredLanguages}
														onSelect={selectedOptions =>
															setPatientNotes(prevState => ({ ...prevState, preferredLanguage: selectedOptions }))
														}
														placeholder={
															isEhrField(MonitoringSettings.PreferredLanguage)
																? intl.formatMessage({ id: 'noDataAvailable' })
																: `${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
																		id: 'preferredLanguage',
																  })}`
														}
														className={classNames({
															'ehr-no-data-input':
																isEhrField(MonitoringSettings.PreferredLanguage) && !patientNotes?.genderIdentity,
															disabled: isEhrField(MonitoringSettings.PreferredLanguage),
														})}
														isMulti={false}
														isDisabled={isEhrField(MonitoringSettings.PreferredLanguage)}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.InterpreterNeeded]) && (
													<label>
														{translate('interpreterNeededText')}
														<div className={classNames('text-input text-input-indication ehr-no-data-input disabled')}>
															<p>
																{patientNotes?.isInterpreterRequired?.toString()
																	? translate(patientNotes?.isInterpreterRequired ? 'yes' : 'no')
																	: translate('noDataAvailable')}
															</p>
														</div>
													</label>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.TitleBarNotes]) && (
													<FormInput
														text={translate('titleBarNotes')}
														id='titleBarNotes'
														placeholder={intl.formatMessage({ id: 'enterTitleBarNotes' })}
														type='text'
														value={patientNotes?.titleBarNotes}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, titleBarNotes: event.target.value }))
														}
														className='text-input'
														maxLength={maxCharacterLength}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.AdditionalPatientNotes]) && (
													<label>
														{translate('additionalPatientNotes')}
														<textarea
															className='text-input'
															maxLength={maxCharacterLength}
															onChange={event => {
																event.preventDefault();
																setPatientNotes(prevState => ({ ...prevState, additionalNotes: event.target.value }));
															}}
															value={patientNotes?.additionalNotes}
															name='note'
															rows={3}
															placeholder={intl.formatMessage({ id: 'enterAdditionalPatientNotes' })}
															style={{ fontSize: '14px' }}
														/>
													</label>
												)}
												<Button text={translate('save')} onClick={handleSubmit} />
											</div>
										</Form>
									)}
								</>
							)}
							<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default PatientNotes;
