import { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Button, Input } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { useConference, useConferenceConfigurations, useLocalParticipant } from 'calls/hooks/index.js';
import { ButtonType, KeyCodes } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import { dialOut, getSipInfo } from 'api/sipProvider.js';
import { countriesData, emailValidationRegEx } from 'calls/constants/index.js';
import { useIMask } from 'react-imask';
import CustomButton from 'components/Button.jsx';
import { useSelector } from 'react-redux';
import { APP_CONFIG } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { parsePhoneNumber, isValidPhoneNumber as isValidNumberFromSip } from 'libphonenumber-js';
import { getConfigurationVariant } from 'infrastructure/helpers/commonHelpers.js';
import { InviteViaSmsVariant, RoundingSettings } from 'constants/configurationEnums.js';
import { participant as CallsParticipant } from '@solaborate/calls';

const TokenInputs = ({ tokens, onRemove }) => (
	<ul>
		{tokens.map(item => (
			<li key={item.id}>
				{item.value}
				<CustomButton onClick={() => onRemove(item)} icon='close' />
			</li>
		))}
	</ul>
);

/**
 * @param {object} props
 * @param {(err) => void} props.setError
 * @param {(err) => void} props.onDismiss
 * @param {object} props.configurations
 */

const AddPeopleView = ({ onDismiss, setError, configurations }) => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const conference = useConference();
	const conferenceConfigs = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const [inviteLinkText, setInviteLinkText] = useState(intl.formatMessage({ id: 'copy' }));
	const userSession = useSelector(state => state.user.userSession);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const [email, setEmail] = useState('');
	const [emails, setEmails] = useState([]);
	const [emailError, setEmailError] = useState(null);
	const [dialInNumbers, setDialInNumbers] = useState([]);
	const [accessCode, setAccessCode] = useState(null);
	const [dialOutNumbers, setDialOutNumbers] = useState([]);
	const [dialOutPhoneError, setDialOutPhoneError] = useState(null);
	const [sipInfo, setSipInfo] = useState(null);
	const [sipError, setSipError] = useState(null);
	const [invitationNote, setInvitationNote] = useState('');
	const copyLinkTimeoutRef = useRef(null);
	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [phoneNumberError, setPhoneNumberError] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(countriesData.US);
	const [dialOutCountryCode, setDialOutCountryCode] = useState(countriesData.US);
	const [invitationNoteError, setInvitationNoteError] = useState(null);
	const { ref: phoneInputRef, maskRef: phoneInputMaskRef } = useIMask({ mask: selectedCountry.phonePattern, lazy: false });
	const { ref: dialOutPhoneInputRef, maskRef: dialOutPhoneInputMaskRef } = useIMask({
		mask: dialOutCountryCode.phonePattern,
		lazy: false,
	});

	const getDialInNumbers = numbers =>
		numbers.map(({ number, state }) => {
			let formattedNumber = '';
			if (isValidNumberFromSip(String(number), state)) {
				const parsedNumber = parsePhoneNumber(String(number), state);
				formattedNumber = `+${parsedNumber.countryCallingCode} ${parsedNumber.formatNational()}`;
			} else {
				formattedNumber = String(number);
			}
			return {
				number: formattedNumber,
			};
		});

	useEffect(() => {
		if (configurations.isInviteViaSmsOn && phoneInputMaskRef?.current) {
			phoneInputMaskRef.current.mask = selectedCountry.phonePattern;
			phoneInputMaskRef.current.unmaskedValue = '';
		}
	}, [selectedCountry, phoneInputMaskRef]);

	useEffect(() => {
		const getSip = async () => {
			const response = await getSipInfo(conference.conferenceId);
			if (response.error) {
				setSipError(<small>{translate('sipNotAvailable')}</small>);
				return;
			}
			setSipInfo(response);
			setAccessCode(response.accessCode);
			const numbers = getDialInNumbers(response.numbers);
			setDialInNumbers(numbers ?? []);
		};
		if (
			(configurations.isDialInOn || configurations.isDialOutOn || configurations.isTranslationServicesOn) &&
			userSession.healthSystem.id
		) {
			getSip();
		}
		return () => {
			if (copyLinkTimeoutRef.current) {
				clearTimeout(copyLinkTimeoutRef.current);
			}
		};
	}, []);

	const isValidEmail = value => {
		if (!value) {
			return false;
		}
		if (emails.some(e => e.value === value)) {
			setEmailError(<small>{translate('emailAlreadyExists')}</small>);
			return false;
		}
		if (!emailValidationRegEx.test(value)) {
			setEmailError(<small>{translate('pleaseEnterValidEmail')}</small>);
			return false;
		}
		return true;
	};

	const removeEmail = ({ id }) => setEmails(prevState => [...prevState.filter(item => item.id !== id)]);

	const removePhoneNumber = ({ id }) => {
		setPhoneNumbers(prevState => [...prevState.filter(item => item.id !== id)]);
	};

	const onCountryChanged = code => {
		setSelectedCountry(countriesData[code]);
	};

	const onDialOutCountryChanged = code => {
		setDialOutCountryCode(countriesData[code]);
	};

	const shouldShowInvitationNote = () =>
		getConfigurationVariant(roleRoundingConfigurations[RoundingSettings.InviteViaSms]) ===
		InviteViaSmsVariant.WITH_INVITATION_NOTE;

	const addEmail = event => {
		const { value } = event.target;
		if (event.which !== KeyCodes.ENTER) {
			return;
		}
		if (!isValidEmail(value)) {
			return;
		}
		setEmails(prevState => [...prevState, { id: prevState.length, value }]);
		setEmail('');
		setEmailError('');
	};

	const copyToClipboard = async () => {
		const updateInviteLinkText = text => {
			setInviteLinkText(text);
			if (copyLinkTimeoutRef.current) {
				clearTimeout(copyLinkTimeoutRef.current);
			}
			copyLinkTimeoutRef.current = setTimeout(() => {
				setInviteLinkText(intl.formatMessage({ id: 'copy' }));
			}, 3000);
		};
		try {
			// @ts-ignore
			const { state } = await navigator.permissions.query({ name: 'clipboard-write' });
			if (state === 'granted' || state === 'prompt') {
				await navigator.clipboard.writeText(conference.link);
				updateInviteLinkText(intl.formatMessage({ id: 'copied' }));
			}
		} catch (err) {
			updateInviteLinkText(intl.formatMessage({ id: 'failedToCopyLink' }));
			// eslint-disable-next-line no-console
			console.warn('Failed to copy conference link!', err);
		}
	};

	const isValidSip = value => {
		if (!value) {
			return false;
		}
		if (dialOutNumbers.some(e => e.value === value)) {
			setDialOutPhoneError(<small>{translate('phoneNumberExists')} </small>);
			return false;
		}
		if (!dialOutPhoneInputMaskRef.current.masked.isComplete) {
			setDialOutPhoneError(<small>{translate('pleaseEnterValidNumber')} </small>);
			return false;
		}
		return true;
	};

	const isValidPhoneNumber = value => {
		if (!value) {
			return false;
		}
		if (phoneNumbers.some(e => e.value === value)) {
			setPhoneNumberError(<small>{translate('phoneNumberExists')}</small>);
			return false;
		}
		if (!phoneInputMaskRef.current.masked.isComplete) {
			setPhoneNumberError(<small>{translate('pleaseEnterValidNumber')} </small>);
			return false;
		}
		return true;
	};

	const addDialOutNumber = event => {
		const { value } = event.target;
		if (event.which !== KeyCodes.ENTER) {
			return;
		}
		if (!isValidSip(value)) {
			return;
		}
		const newPhoneNumber = {
			id: dialOutNumbers.length,
			value: dialOutPhoneInputMaskRef.current.value,
			unmaskedValue: `+${dialOutPhoneInputMaskRef.current.unmaskedValue}`,
		};
		setDialOutNumbers(prevState => [...prevState, newPhoneNumber]);
		dialOutPhoneInputMaskRef.current.unmaskedValue = '';
		setDialOutPhoneError('');
	};

	const addPhoneNumber = event => {
		const { value } = event.target;
		if (event.which !== KeyCodes.ENTER) {
			return;
		}
		if (!isValidPhoneNumber(value)) {
			return;
		}
		const newPhoneNumber = {
			id: phoneNumbers.length,
			value: phoneInputMaskRef.current.value,
			unmaskedValue: `+${phoneInputMaskRef.current.unmaskedValue}`,
		};
		setPhoneNumbers(prevState => [...prevState, newPhoneNumber]);
		phoneInputMaskRef.current.unmaskedValue = '';
		setPhoneNumberError('');
	};

	const removeParticipantOnNoAnswer = (participantId, message = '') => {
		setTimeout(() => {
			const participant = conference.participants.get(participantId);
			const isParticipantConnecting = participant && participant.state instanceof CallsParticipant.StateConnecting;
			if (isParticipantConnecting) {
				conference.removeParticipant(participantId);
				onDismiss(message);
			}
		}, 60000);
	};

	const inviteDialOut = async () => {
		const newDialOutNumbers = [...dialOutNumbers];
		newDialOutNumbers.push({ unmaskedValue: `+${dialOutPhoneInputMaskRef.current.unmaskedValue}` });
		const response = await dialOut(newDialOutNumbers[0].unmaskedValue, conference.conferenceId);
		if (response.error) {
			setError(translate('phoneNotReachable'));
		} else {
			removeParticipantOnNoAnswer(response.participantId);
			onDismiss();
		}
	};

	const onSubmit = async () => {
		const phoneNumberOnInput = phoneInputMaskRef?.current?.unmaskedValue?.length - (selectedCountry.phonePrefix.length - 1);
		const emailOnInput = email.length;
		if (!emailOnInput && !phoneNumberOnInput && !emails?.length && !phoneNumbers?.length) {
			return;
		}
		const newEmails = [...emails];
		const newPhoneNumbers = [...phoneNumbers];
		if (emailOnInput) {
			if (!isValidEmail(email)) {
				return;
			}
			newEmails.push({ value: email });
		}
		if (phoneNumberOnInput) {
			if (!isValidPhoneNumber(phoneInputMaskRef.current.unmaskedValue)) {
				return;
			}
			newPhoneNumbers.push({ unmaskedValue: `+${phoneInputMaskRef.current.unmaskedValue}` });
		}
		if (shouldShowInvitationNote() && phoneNumberOnInput && invitationNote.trim().length < 2) {
			setInvitationNoteError(<small>{translate('invitationNoteMinLength')}</small>);
			return;
		}

		const fullStops = invitationNote.split('.');
		let newInvitationNote = '';
		if (fullStops.length > 1 && invitationNote[invitationNote.length - 1] === '.') {
			const lastFullStopIndex = invitationNote.lastIndexOf('.');
			newInvitationNote = invitationNote.substring(0, lastFullStopIndex);
		}
		await conference.inviteParticipants({
			participants: [],
			emailList: newEmails.map(e => e.value),
			phoneNumberList: newPhoneNumbers.map(p => p.unmaskedValue),
			siteName: APP_CONFIG.URL.localApiBasePath,
			message: !newInvitationNote ? invitationNote : newInvitationNote,
			healthSystemName: userSession.healthSystem.name,
		});
		const invitedParticipantsResponse = await socket.emitWithAck(SocketEvents.Conference.GET_INVITATION_LINKS, {
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
		});
		if (invitedParticipantsResponse) {
			conferenceConfigs.setInvitedParticipants(invitedParticipantsResponse);
		}
		onDismiss();
	};

	return (
		<>
			<Modal.Content>
				<div>
					{conference?.link && configurations.isMeetingURLOn && (
						<main>
							<Input>
								<Input.Label>{translate('directLink')}</Input.Label>
								<div>
									<Input.Field value={conference.link} onKeyPress={addEmail} readOnly={true} />
									<Button type='button' onClick={copyToClipboard}>
										{inviteLinkText}
									</Button>
								</div>
								<Input.Description>{translate('sendLinkToPeople')}</Input.Description>
							</Input>
						</main>
					)}
					{configurations.isInviteViaEmailOn && (
						<Input>
							<Input.Label>{translate('inviteViaEmail')}</Input.Label>
							{emails?.length > 0 && <TokenInputs tokens={emails} onRemove={removeEmail} />}
							<Input.Field
								placeholder={intl.formatMessage({ id: 'addEmail' })}
								value={email}
								onKeyPress={addEmail}
								onChange={event => setEmail(event.target.value)}
							/>
							<Input.Description>{emailError || intl.formatMessage({ id: 'pleaseEnterToAddEmail' })}</Input.Description>
						</Input>
					)}
					{configurations.isInviteViaSmsOn && (
						<Input>
							<Input.Label>{translate('inviteViaSms')}</Input.Label>
							{phoneNumbers?.length > 0 && <TokenInputs tokens={phoneNumbers} onRemove={removePhoneNumber} />}
							<section>
								<select value={selectedCountry?.code} onChange={ev => onCountryChanged(ev.target.value)} disabled={true}>
									<option hidden value={selectedCountry?.code} label={selectedCountry?.code} />
									{Object.values(countriesData).map(({ code, name, phonePrefix }) => (
										<option key={code} value={code} label={`${name} ${phonePrefix}`} />
									))}
								</select>
								<Input.Field
									ref={phoneInputRef}
									placeholder={intl.formatMessage({ id: 'inviteViaSms' })}
									onKeyPress={addPhoneNumber}
								/>
							</section>
							<Input.Description>
								{phoneNumberError || intl.formatMessage({ id: 'pressEnterToAddPhoneNumber' })}
							</Input.Description>
						</Input>
					)}
					{configurations.isDialInOn && userSession.healthSystem.id && sipInfo && !sipError && (
						<Input>
							<Input.Label>{translate('joinViaPhone')}</Input.Label>
							<section>
								<div className='sip-info-container'>
									{dialInNumbers && accessCode && (
										<>
											<div>
												<Input.Description>{intl.formatMessage({ id: 'dialIn' })}</Input.Description>
												{dialInNumbers.map(({ number }) => (
													<Input.Field key={number} readOnly value={number} className='sip-input' />
												))}
											</div>
											<div>
												<Input.Description>{intl.formatMessage({ id: 'accessCode' })}</Input.Description>
												<Input.Field readOnly value={`${accessCode}#`} className='sip-input' />
											</div>
										</>
									)}
									{!dialInNumbers && !accessCode && <div>{intl.formatMessage({ id: 'sipNotAvailable' })}</div>}
								</div>
							</section>
						</Input>
					)}
					{configurations.isDialOutOn && userSession.healthSystem.id && sipInfo && !sipError && (
						<Input>
							<Input.Label>{intl.formatMessage({ id: 'callPhoneNumber' })}</Input.Label>
							<section className='dial-out'>
								<select
									value={dialOutCountryCode?.code}
									onChange={ev => onDialOutCountryChanged(ev.target.value)}
									disabled={true}>
									<option hidden value={dialOutCountryCode?.code} label={dialOutCountryCode?.code} />
									{Object.values(countriesData).map(({ code, name, phonePrefix }) => (
										<option key={code} value={code} label={`${name} ${phonePrefix}`} />
									))}
								</select>
								<Input.Field
									ref={dialOutPhoneInputRef}
									placeholder={intl.formatMessage({ id: 'callPhoneNumber' })}
									onKeyPress={addDialOutNumber}
								/>
								<Button type='submit' variant={ButtonType.SUBMIT} onClick={inviteDialOut}>
									{translate('callNumber')}
								</Button>
							</section>
							<Input.Description>
								{dialOutPhoneError || intl.formatMessage({ id: 'pressEnterToAddPhoneNumber' })}
							</Input.Description>
						</Input>
					)}
					{shouldShowInvitationNote() && (
						<Input>
							<Input.Label>{intl.formatMessage({ id: 'invitationNote' })}</Input.Label>
							<Input.Field
								type='text'
								value={invitationNote}
								onChange={e => setInvitationNote(e.target.value)}
								placeholder={intl.formatMessage({ id: 'invitationNotePlaceholder' })}
								maxLength={160}
							/>
							<Input.Description>
								{invitationNoteError || intl.formatMessage({ id: 'invitationNoteDescription' })}
							</Input.Description>
						</Input>
					)}
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button type='submit' onClick={onSubmit} variant={ButtonType.SUBMIT}>
					{translate('sendInvite')}
				</Button>
			</Modal.Actions>
		</>
	);
};

export default AddPeopleView;
