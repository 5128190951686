import useConferenceConfigurations from 'calls/hooks/useConferenceConfigurations.js';
import { requestStatusIds } from 'constants/voyceEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { getObjectById } from 'infrastructure/helpers/commonHelpers.js';

const InterpreterStatus = () => {
	const conferenceConfigs = useConferenceConfigurations();
	const canCloseInterpreterStatus = () =>
		[requestStatusIds.CANCELED.id, requestStatusIds.SERVICED.id, requestStatusIds.NO_INTERPRETER_AVILABLE.id].includes(
			conferenceConfigs.interpreterInvitedMessage
		);

	return (
		conferenceConfigs.interpreterInvitedMessage && (
			<div className='invited-interpreter-status'>
				{canCloseInterpreterStatus() && (
					<div className='close-icon' onClick={() => conferenceConfigs.setInterpreterInvitedMessage(null)}>
						X
					</div>
				)}
				<span style={{ paddingRight: canCloseInterpreterStatus() ? 15 : 0 }}>
					{translate('interpreter')} {translate('status')}:{' '}
					{getObjectById(conferenceConfigs.interpreterInvitedMessage, requestStatusIds)?.description}
				</span>
			</div>
		)
	);
};

export default InterpreterStatus;
