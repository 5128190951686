import { useEffect, useState, useContext, useCallback } from 'react';
import { Modal, Button } from 'calls/components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { APP_CONFIG } from 'constants/global-variables.js';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { useConference, useConferenceParticipants, useLocalParticipant } from 'calls/hooks/index.js';
import { findSectorById, generateUID, removeTrailingSlash } from 'infrastructure/helpers/commonHelpers.js';
import { createAssignment, deleteAssignment, getGloboLanguages, getGloboServices } from 'api/globo.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import { ButtonType } from 'constants/enums.js';
import CustomSelect from 'components/Select.jsx';
import { AssignmentStatus } from 'constants/globoEnums.js';
import { useIntl } from 'react-intl';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { configurationTypeIds } from 'constants/integrationEnums.js';

/**
 * @param {object} props
 * @param {(err) => void} props.setError
 * @param {string} props.roomId
 * @param {object} props.interpretationConfig
 */

const InviteGloboView = ({ roomId, setError, interpretationConfig }) => {
	const intl = useIntl();
	const callTypes = [
		{ id: 1, name: intl.formatMessage({ id: 'videoAndAudioOption' }) },
		{ id: 2, name: intl.formatMessage({ id: 'audioOnlyOption' }) },
	];
	const socket = useContext(SocketContext);
	const conferenceParticipants = useConferenceParticipants();
	const userSession = useSelector(state => state.user.userSession);
	const conference = useConference();
	const localParticipant = useLocalParticipant();
	const healthSystems = useSelector(state => state.healthSystems);
	const socketFunctions = useContext(SocketFunctionsContext);
	const [selectedTargetLanguage, setSelectedTargetLanguage] = useState({ id: '', name: '' });
	const [selectedSourceLanguage, setSelectedSourceLanguage] = useState({ id: '', name: '' });
	const [patientPreferredLanguage, setPatientPreferredLanguage] = useState(null);
	const [activeAssignment, setActiveAssignment] = useState(null);
	const [globoLanguages, setGloboLanguages] = useState([]);
	const [invitationSecret, setInvitationSecret] = useState(null);
	const [selectedCallTypeId, setSelectedCallTypeId] = useState(
		interpretationConfig?.callType ? callTypes.find(item => interpretationConfig.callType === item.id).id : callTypes[0].id
	);
	const [globoServiceId, setGloboServiceId] = useState(null);

	useEffect(() => {
		fetchGlobalLanguages(interpretationConfig.baseUrl);
		const handleAssignmentStatus = data => {
			setActiveAssignment(prevState => ({
				...data,
				uuid: prevState?.uuid,
			}));
		};
		socket.on(SocketEvents.Conference.INTERPRETER_RESOURCE_UPDATED, handleAssignmentStatus);
		return () => {
			socket.off(SocketEvents.Conference.INTERPRETER_RESOURCE_UPDATED, handleAssignmentStatus);
		};
	}, []);

	const getTreeHierarchyParams = () => {
		const sector = findSectorById(healthSystems.treeData.tree, roomId);
		const { hospitalId, departmentId, floorId } = sector;
		return { healthSystemId: userSession.healthSystem.id, hospitalId, departmentId, floorId, roomId };
	};

	const fetchGlobalLanguages = async baseUrl => {
		const location = getTreeHierarchyParams();
		const serviceResponse = await getGloboServices(baseUrl, location);

		if (serviceResponse.error) {
			setError(serviceResponse.error.message);
			return;
		}
		const resGloboServiceId = serviceResponse?.services?.[0]?.id ?? null;
		if (!resGloboServiceId || !baseUrl) {
			return;
		}
		setGloboServiceId(resGloboServiceId);
		const response = await getGloboLanguages({ baseUrl, location, globoServiceId: resGloboServiceId });
		if (response.error) {
			setError(response.error.message);
			return;
		}
		handleLanguageResponse(response);
	};

	const handleLanguageResponse = response => {
		const languages = response.languages;
		const patientInfo = conference.additionalData.find(item => item.key === 'patientInfo');
		setGloboLanguages(languages);

		if (languages.length > 0) {
			setSelectedSourceLanguage(languages[0]);
		}

		if (patientInfo) {
			setPatientPreferredLanguage(patientInfo.value.preferredLanguage);
			const foundLanguage = languages.find(
				item =>
					patientInfo.value.preferredLanguage &&
					item.name.toLowerCase().startsWith(patientInfo.value.preferredLanguage.toLowerCase())
			);
			if (foundLanguage) {
				setSelectedTargetLanguage(foundLanguage);
				return;
			}
		}
		if (languages.length > 1) {
			setSelectedTargetLanguage(languages[1]);
		}
	};

	const inviteInterpreter = async () => {
		const uuid = v4();
		const generatedSecretUid = generateUID(10);
		const baseUrl = APP_CONFIG.URL.localApiBasePath.replace('localhost:3000', 'hellocare.dev.solaborate.com').trim();
		const params = {
			healthSystemId: userSession.healthSystem.id,
			inviteUrl: `${removeTrailingSlash(baseUrl)}/call-session/${
				conference.conferenceId
			}/invitation/${uuid}|${generatedSecretUid}?interpreterId=${uuid}`,
			sourceLanguage: 'English',
			targetLanguage: selectedTargetLanguage.name,
			callbacks: [
				{
					url: `${removeTrailingSlash(APP_CONFIG.URL.gatewayApiBasePath)}/v1/virtual-sessions/${
						conference.conferenceId
					}/interpreter/${uuid}/callback`,
					statuses: ['resource-assigned', 'in-progress', 'completed'],
				},
			],
			baseUrl: interpretationConfig.baseUrl,
			globoServiceId,
			...(interpretationConfig.costCenterCode && {
				questions: [
					{
						name: 'ccc',
						value: interpretationConfig.costCenterCode,
					},
				],
			}),
		};
		setInvitationSecret(generatedSecretUid);
		const location = getTreeHierarchyParams();
		const response = await createAssignment(params, location);
		if (response?.error) {
			setError(response.error.message);
			return;
		}
		await socketFunctions.sendInterpreterAssignmentRequest({
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			methodType: 'create',
			data: {
				uid: uuid,
				healthSystemId: userSession.healthSystem.id,
				companyId: getCompanyId(),
				invitationSecret: generatedSecretUid,
				callType: selectedCallTypeId,
				provider: configurationTypeIds.GLOBO.description,
			},
		});
		setActiveAssignment({ status: AssignmentStatus.NEW, id: response.id, uuid });
	};

	const getRequestStatusDescription = status => {
		let result = '';
		switch (status) {
			case AssignmentStatus.CANCELED: {
				result = intl.formatMessage({ id: 'canceled' });
				break;
			}
			case AssignmentStatus.COMPLETED: {
				result = intl.formatMessage({ id: 'completed' });
				break;
			}
			case AssignmentStatus.IN_PROGRESS: {
				result = intl.formatMessage({ id: 'inProgress' });
				break;
			}
			case AssignmentStatus.NEW: {
				result = intl.formatMessage({ id: 'new' });
				break;
			}
			case AssignmentStatus.RESOURCE_ASSIGNED: {
				result = intl.formatMessage({ id: 'resourceAssigned' });
				break;
			}
			default: {
				result = 'N/A';
			}
		}
		return result;
	};

	const onTargetLanguageSelect = event => {
		const foundItem = globoLanguages.find(language => language.id.toString() === event.target.value);
		if (!foundItem) {
			return;
		}
		setSelectedTargetLanguage(foundItem);
	};

	const cancelRequest = async () => {
		const deleteParams = {
			healthSystemId: userSession.healthSystem.id,
			assignmentId: activeAssignment.id,
			baseUrl: interpretationConfig.baseUrl,
			globoServiceId,
		};
		const location = getTreeHierarchyParams();
		const response = await deleteAssignment(deleteParams, location);
		if (response?.error) {
			setError(response.error.message);
			return;
		}

		await socketFunctions.sendInterpreterAssignmentRequest({
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			methodType: 'cancel',
			data: {
				uid: activeAssignment.uuid,
				healthSystemId: userSession.healthSystem.id,
				companyId: getCompanyId(),
				invitationSecret,
				callType: selectedCallTypeId,
			},
		});
		setActiveAssignment(null);
	};

	const showInviteButton = () =>
		!activeAssignment || [AssignmentStatus.CANCELED, AssignmentStatus.COMPLETED].includes(activeAssignment?.status);

	return (
		<>
			<Modal.Content>
				<div className='translation-services'>
					{patientPreferredLanguage && (
						<div className='bottom-20'>
							{translate('patientPreferredLanguage')}: {patientPreferredLanguage.name}
						</div>
					)}
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label='Target Language'
							name='selectLanguage'
							items={globoLanguages.filter(item => item.id !== selectedSourceLanguage.id)}
							valueField='id'
							textField='name'
							placeholder='Select Language'
							value={selectedTargetLanguage.id}
							onSelect={onTargetLanguageSelect}
						/>
					</div>
					<div>
						<CustomSelect
							labelClassName='full-width flex'
							className='margin-left-auto'
							label={intl.formatMessage({ id: 'callType' })}
							name='selectCallType'
							items={callTypes}
							valueField='id'
							textField='name'
							placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'callType' })}`}
							value={selectedCallTypeId}
							onSelect={event => setSelectedCallTypeId(+event.target.value)}
						/>
					</div>
					{activeAssignment?.status && (
						<div>
							{translate('status')}: {getRequestStatusDescription(activeAssignment.status)}
						</div>
					)}
				</div>
			</Modal.Content>
			<Modal.Actions>
				{showInviteButton() && (
					<Button
						type='submit'
						onClick={inviteInterpreter}
						variant={ButtonType.SUBMIT}
						disabled={!selectedSourceLanguage.id || !selectedTargetLanguage.id}>
						{translate('inviteInterpreter')}
					</Button>
				)}
				{!conferenceParticipants.some(item => item.interpreterId) && !showInviteButton() && (
					<Button type='submit' onClick={cancelRequest} variant={ButtonType.SUBMIT}>
						{translate('cancel')}
					</Button>
				)}
			</Modal.Actions>
		</>
	);
};

export default InviteGloboView;
