import { gatewayApi, legacyFileUploadApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getHistoryByConversationId = async (conversationId, latest, limit) => {
	let url = `v1/conversations/${conversationId}/history`;
	if (latest) {
		url += `?latest=${latest}`;
	}
	if (limit) {
		url += `${latest ? '&' : '?'}limit=${limit}&_=${new Date().getTime()}`;
	}
	try {
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConversationByType = async (conversationTypeId, objectId, objectTypeId) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/tenants/${getCompanyId()}/conversations/types/${conversationTypeId}/object-types/${objectTypeId}/objects/${objectId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const insertMessage = async (conversationId, messageObj) => {
	try {
		const url = `v1/conversations/${conversationId}/messages`;
		const { data } = await gatewayApi.post(url, messageObj);
		return data;
	} catch (error) {
		return { error };
	}
};

export const uploadAttachment = async (file, isEncrypted, conversationData, fileLoadingPercentage) => {
	try {
		const formData = new FormData();
		const url = `v2.2/api/messages/attachments`;
		formData.append('file', file);
		formData.append('isEncrypted', isEncrypted || false);
		formData.append('conversationData', JSON.stringify(conversationData));
		const { data } = await legacyFileUploadApi.post(url, formData, {
			onUploadProgress: progressEvent => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				fileLoadingPercentage(percentCompleted);
			},
		});
		return data;
	} catch (error) {
		return { error: error.response.data };
	}
};

export const getConversationAttachments = async (conversationId, dataToSend) => {
	const { latest, getMedia, getDocuments, limit } = dataToSend;
	try {
		const { data } = await gatewayApi.get(`v1/conversations/${conversationId}/attachments?`, {
			params: {
				latest,
				getMedia,
				getDocuments,
				limit,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMessage = async (messageId, deleteForEveryone) => {
	try {
		const { data } = await gatewayApi.delete(`v1/messages/${messageId}?deleteForEveryone=${deleteForEveryone}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRecent = async userId => {
	try {
		const { data } = await gatewayApi.get(`v1/users/${userId}/conversations/recent/private`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateReadStatus = async conversationId => {
	try {
		const { data } = await gatewayApi.put(`v1/conversations/${conversationId}/read`, { isRead: true });
		return data;
	} catch (error) {
		return { error };
	}
};

export const setAllReadConversation = async () => {
	try {
		const { data } = await gatewayApi.put(`v1/conversations/status/read`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConversationLinks = async conversationId => {
	try {
		const { data } = await gatewayApi.get(`v1/conversations/${conversationId}/links`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getChannelMembers = async (teamId, channelId) => {
	try {
		const { data } = await gatewayApi.get(`v1/companies/${getCompanyId()}/teams/${teamId}/channels/${channelId}/members`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConversationMembers = async conversationId => {
	try {
		const { data } = await gatewayApi.get(`v1/conversations/${conversationId}/members`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMemberByObjectType = async (objectId, objectType = 0) => {
	try {
		const { data } = await gatewayApi.get(`v1/objects/object-type/${objectType}/object-ids/${objectId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMembersByObjectType = async (objects = []) => {
	try {
		const { data } = await gatewayApi.post(`/v1/companies/${getCompanyId()}/objects`, {
			objects: objects,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getBabyRoomChannels = async () => {
	try {
		const { data } = await gatewayApi.get(`v1/companies/${getCompanyId()}/baby-room-channels`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const unAssignVCPtoBabyRoom = async (nurseUserId, roomId) => {
	try {
		const { data } = await gatewayApi.put(`v1/organizations/${getCompanyId()}/rooms/${roomId}/unassign-nurse`, {
			vcpUserId: nurseUserId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const assignVCPtoBabyRoom = async (nurseUserId, roomId) => {
	try {
		const { data } = await gatewayApi.post(`v1/organizations/${getCompanyId()}/rooms/${roomId}/assign-nurse`, {
			vcpUserId: nurseUserId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
